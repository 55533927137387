import { AlertComponentProps } from 'components/AlertComponent/AlertComponent';
import { externalLinks } from 'lib/externalLinks';

interface DateSpecificAlertContent extends AlertComponentProps {
  start: Date;
  end: Date;
}

const timedAlerts: DateSpecificAlertContent[] = [
  {
    title: 'We’re working to keep your information safe',
    variation: 'Timed alert',
    listItem: [
      'We have updated our system to make it more secure to complete your survey online.',
      'If this is your first time logging in since 28 March 2025 you will be asked to set a new password and verify your email address.',
    ],
    start: new Date('2025-01-01'),
    end: new Date('2025-08-08'),
  },
];

export const content = {
  welcome: 'Welcome to',
  heading: 'ABS Business Reporting',
  summary: 'Complete your survey, quickly and securely.',
  secondSummary:
    'Each quarter, we ask businesses to provide information to help build a picture of businesses across Australia.',
  list: [
    'This picture helps government, industry and others to understand the challenges and successes that small businesses like yours are experiencing.',
    'Information you provide is collected under the Census and Statistics Act 1905, meaning we are legally required to protect your data and maintain its confidentiality.',
    'If your business uses Xero, MYOB or QuickBooks, connect to your accounting software to make it faster and easier to complete your survey.',
  ],
  linkPrivacy: externalLinks.absDataPrivacySecurity,
  linkDataUsage: externalLinks.absBusinessReportingDataUsage,
  linkContact: externalLinks.absContactUs,
  timeOut: {
    title: 'Your session timed out',
    variation: 'timeOut',
    listItem: [
      'We noticed you were inactive and logged you out for your security.',
      'Please log in to continue your survey.',
    ],
  },
  oktaUnverified: {
    title: 'Log in failed',
    variation: 'error',
    listItem: [
      'You were unable to log in with your accounting software due to an earlier attempt at creating an ABS account.',
      'Please click the activation link you received in an email, or press ‘Log in’ below and enter your email to continue.',
    ],
  },
  timedAlerts,
};
