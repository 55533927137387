import { useEffect, useState } from 'react';
import { Flex, Text, useBreakpointValue } from '@aws-amplify/ui-react';
import { Snackbar } from '@mui/material';

import { ReactComponent as SvgCloseIcon } from 'assets/icon-x.svg';
import { ReactComponent as SvgGreenTickIcon } from 'assets/icon-green-tick.svg';
import { ReactComponent as SvgRedCrossIcon } from 'assets/icon-x-circle.svg';

import './ToastNotification.css';

const noop: () => any = () => {};

interface ToastNotificationProps {
  success: boolean;
  title: string;
  text?: string;
  open: boolean;
  onClose?: () => any;
}
const ToastNotification = (props: ToastNotificationProps) => {
  const { success, title, text, open, onClose } = props;

  const [notificationOpen, setNotificationOpen] = useState(false);

  const handleCloseNotification = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setNotificationOpen(false);
  };

  useEffect(() => {
    setNotificationOpen(open);
  }, [open]);

  // Inform parent that this has closed
  const notifyClose = onClose ?? noop;
  useEffect(() => {
    if (!notificationOpen) {
      notifyClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationOpen]);

  const styleLeftValue = useBreakpointValue({
    base: 'var(--amplify-space-medium)',
    medium: '62px',
  }) as string;

  return (
    <Snackbar
      open={notificationOpen}
      onClose={handleCloseNotification}
      ContentProps={{
        style: {
          backgroundColor: '#FFF',
          border: '1px solid #EDEDED',
          borderRadius: '8px',
          boxShadow: '0px 20px 24px -4px rgba(0, 0, 0, 0.1), 0px 8px 8px -4px rgba(0, 0, 0, 0.04)',
          width: '600px',
        },
      }}
      message={
        <Flex alignItems="center" gap="20px">
          <Flex direction="column">{success ? <SvgGreenTickIcon /> : <SvgRedCrossIcon />}</Flex>
          <Flex direction="column" gap="10px" padding="10px 0">
            <Text className={`notification-heading ${!success ? 'error-text' : ''}`}>{title}</Text>
            {text && <Text className="notification-text">{text}</Text>}
          </Flex>
          <SvgCloseIcon className="notification-close-icon" onClick={handleCloseNotification} />
        </Flex>
      }
      style={{ bottom: '77px', left: styleLeftValue, right: 'var(--amplify-space-medium)' }}
    />
  );
};

export default ToastNotification;
