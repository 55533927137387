import { Grid, Loader } from '@aws-amplify/ui-react';
import { AccessDetail } from 'models';
import DashboardInfo from 'components/DashboardInfo/DashboardInfo';
import { formatAbn } from 'utils/formatAbn';
import { useBreakpointValue } from 'utils/useBreakpointValueTyped';

const LoadedDashboardBusinessInformation = ({ selectedOrganisation }: { selectedOrganisation: AccessDetail }) => (
  <Grid className="dashboard-data-wrapper">
    <DashboardInfo
      heading="Business Name"
      uniqueId="trading-name"
      data={selectedOrganisation?.organisationDetails?.providerDetails.name}
      style={{ gridColumn: useBreakpointValue({ base: '1/-1', small: '1/3', large: '1/2' }) }}
    />
    <DashboardInfo
      heading="ABN"
      uniqueId="business-abn"
      data={formatAbn(selectedOrganisation?.organisationDetails?.providerDetails.currentPEGAABN)}
      style={{ gridColumn: useBreakpointValue({ base: '1/-1', small: '3/-1', large: '2/2' }) }}
    />
    <DashboardInfo
      heading="State"
      uniqueId="state"
      data={selectedOrganisation?.organisationDetails?.providerDetails?.address?.state}
    />
    <DashboardInfo
      heading="Postcode"
      uniqueId="postcode"
      data={selectedOrganisation?.organisationDetails?.providerDetails?.address?.postcode}
    />
  </Grid>
);

const LoadingDashboardBusinessInformation = () => (
  <Grid
    className="dashboard-data-wrapper"
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Loader aria-label="Loading..." height="64px" width="64px" style={{ cursor: 'not-allowed' }} />
  </Grid>
);

// Note: this is tested via tests for Dashboard component (but we can add specific tests for this component)
export const DashboardBusinessInformation = ({ selectedOrganisation }: { selectedOrganisation?: AccessDetail }) =>
  selectedOrganisation ? (
    <LoadedDashboardBusinessInformation selectedOrganisation={selectedOrganisation} />
  ) : (
    <LoadingDashboardBusinessInformation />
  );
