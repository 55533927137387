import { Button, Text, View } from '@aws-amplify/ui-react';
import RevokeDelegateModal from 'components/RevokeDelegateModal/RevokeDelegateModal';
import { Delegate } from 'models';
import { useState } from 'react';

interface RevokeDelegateSectionProps {
  delegates: Delegate[];
}

const RevokeDelegateSection = ({ delegates }: RevokeDelegateSectionProps) => {
  const [showRevokeDelegateModal, setShowRevokeDelegateModal] = useState(false);

  return (
    <View>
      <br />
      <Text>
        These reports can be accessed by{' '}
        {delegates.map(({ firstName, lastName, email }) => `${firstName} ${lastName} (${email})`).join(', ')}. You can{' '}
        <Button
          onClick={() => {
            setShowRevokeDelegateModal(true);
          }}
          className="button-link"
        >
          <Text as="span" tabIndex={-1}>
            revoke this access
          </Text>
        </Button>{' '}
        at any time.
      </Text>
      <RevokeDelegateModal
        isOpen={showRevokeDelegateModal}
        close={() => setShowRevokeDelegateModal(false)}
        delegates={delegates}
      />
    </View>
  );
};
export default RevokeDelegateSection;
