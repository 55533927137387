/*
 * Utility functions for predicates and other callbacks that help with filtering
 * and so on, particularly around allowing typescript to properly infer types.
 */

/**
 * Generic predicate to filter undefined values from an array with accurate type inference.
 *
 * i.e. allows Typescript to infer that undefined is not a possible type in the array.
 *
 * @param val value that may be undefined
 * @returns true if the value is defined
 */
export const isDefined = function <T>(val: T | undefined): val is T {
  return typeof val !== 'undefined';
};

// util to use with values that carry a context with them, so that the value can be
// checked and transformed without losing type inference by including the context

interface ValueWithContext<V, C> {
  value: V;
  context: C;
}

/**
 * Make a callback that adds a given context to a value.
 */
export const withContext =
  <V, C>(context: C) =>
  (value: V): ValueWithContext<V, C> => ({ value, context });

/**
 * Map the value without changing the context.
 *
 * Usage (where arr is an array of ValueWithContext):
 *    // example mapping callback, you can use anything
 *    const getNestedVal = (val) => val.nestedVal);
 *    arr.map(mapValueKeepContext(getNestedVal);
 */
export const mapValueKeepContext =
  <C, V, W>(cb: (val: V) => W) =>
  ({ value, context }: ValueWithContext<V, C>): ValueWithContext<W, C> => ({ value: cb(value), context });

/**
 * Filter with a type predicate on the value to get a narrowed type with the value
 * @param predicate a type predicate (i.e. predicate with "is [some type]" in its signature)
 * @returns a callback for filter that will properly infer the type of value
 */
export const filterTypeOnValue =
  <C, V, W extends V>(predicate: (val: V) => val is W) =>
  (foo: ValueWithContext<V, C>): foo is ValueWithContext<W, C> =>
    predicate(foo.value);
