/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable prettier/prettier */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Text,
  Grid,
  Heading,
  Image,
  TextField,
  View,
  Button,
  Link,
  AlertProps,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import { useLazyQuery } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';

import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';
import { InputLabelTooltip } from 'components/InputLabelTooltip/InputLabelTooltip';
import { InlineError } from 'components/InlineError/InlineError';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import { GET_DSP_ACCESS_TOKEN } from 'apollo/queries/getDSPAccessToken';
import { CLAIM_OBLIGATION } from 'apollo/queries/claimObligation';
import { ClaimObligation, GetDSPAccessToken } from 'models';
import { ApiErrorMsg } from 'enums/ApiErrorMsg';
import {
  checkErrorMessages,
  getErrorCode,
  providerDetailsNotFound,
} from 'utils/errorHandling';
import { externalLinks } from 'lib/externalLinks';
import { setActiveBusinessAfterClaim } from 'utils/setActiveBusinessAfterClaim';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import { showNoDsp } from 'utils/getShowNoDspValue';

import ManualAuthHero from 'assets/claim-obligation.jpg';

import './ManualAuthorisation.css';
import { recordRumCustomEventWithPageId } from 'services/awsRum';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';
import { gridBreakPoints } from 'utils/gridBreakPoints';

const errorBannerProps: AlertProps = {
  testId: 'manual-authorise-error-banner',
  isDismissible: true,
  padding: '24px 0',
};

const ManualAuthorisation = () => {
  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ businessId: string }>();
  const navigate = useNavigate();

  const [inlineError, setInlineError] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const [claimedError, setClaimedError] = useState(false);
  const [delegateeExpiredError, setDelegateeExpiredError] = useState(false);
  const [activeErrorCode, setActiveErrorCode] = useState<number>();

  const [callGetDspAccessToken, GetDspAccessTokenResponse] = useLazyQuery<
    GetDSPAccessToken | undefined
  >(GET_DSP_ACCESS_TOKEN, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.getDSPAccessToken.success) {
        callClaimObligation({
          variables: { dsp: 'NODSP', organisationId: getValues().businessId },
        });
      } else {
        setInlineError(true);
      }
    },
    onError: () => {
      setInlineError(true);
    },
  });

  const [callClaimObligation, ClaimObligationResponse] = useLazyQuery<
    ClaimObligation | undefined
  >(CLAIM_OBLIGATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const errorMessages = data?.claimObligation.errorMessages ?? [];
      if (data?.claimObligation.success) {
        if (data.claimObligation.organisationId)
          setActiveBusinessAfterClaim(data.claimObligation.organisationId);
        navigate('/dashboard');
      } else if (
        checkErrorMessages(
          errorMessages,
          ApiErrorMsg.internalSystemError,
          providerDetailsNotFound
        )
      ) {
        setMatchError(true);
        setActiveErrorCode(
          getErrorCode(errorMessages, ApiErrorMsg.internalSystemError)
        );
      } else if (
        checkErrorMessages(errorMessages, ApiErrorMsg.alreadyClaimed)
      ) {
        setClaimedError(true);
        setActiveErrorCode(
          getErrorCode(errorMessages, ApiErrorMsg.alreadyClaimed)
        );
      } else if (
		checkErrorMessages(errorMessages, ApiErrorMsg.delegateeExpired)
	  ) {
		setDelegateeExpiredError(true);
        setActiveErrorCode(
          getErrorCode(errorMessages, ApiErrorMsg.delegateeExpired)
        );
	  } else {
        setInlineError(true);
      }
    },
    onError: () => {
      setMatchError(true);
    },
  });

  const handleAuthSubmit = () => {
    setInlineError(false);
    setMatchError(false);
    setClaimedError(false);
	setDelegateeExpiredError(false);
    setActiveErrorCode(undefined);
    callGetDspAccessToken({ variables: { dsp: 'NODSP', authCode: '' } });
  };

 const selectBusinessPageWrapperColumns = useBreakpointValue({
    base: '2/14',
    medium: '2/10',
    large: '2/9',
    xl: '2/10',
  }) as string;

  const heroImageWrapperColumns = useBreakpointValue({
    base: '2/14',
    medium: '10/14',
    large: '10/14',
    xl: '10/14',
  }) as string;



  const gridMainBreakPoints = useBreakpointValue(
    gridBreakPoints as Record<string, unknown>
  ) as string;

  return showNoDsp ? (
		<><View>
			{matchError && (
				<AlertComponent
					title='Entered reference number does not match our records'
					onDismiss={() => setMatchError(false)}
					textHeaderComponent={<>
						Your reference number is two letters (AT or MU) followed by
						eight numbers such as 'AT12345678'. This can be found in the
						email or letter you received asking you to complete your survey.
						<br />
						<br />
						If you are still having trouble please{' '}
						<Link
							href={externalLinks.absContactUs}
							isExternal
							onClick={() => recordRumCustomEventWithPageId(
								RumCustomEvent.contactUsClicked,
								getRumAttributes()
							)}
						>
							contact us
						</Link>{' '}
						for assistance.
					</>}
					variation='error'
					alertProps={errorBannerProps}
					errorCode={activeErrorCode} />
			)}
			{claimedError && (
				<AlertComponent
					title='Reference number already claimed'
					onDismiss={() => setClaimedError(false)}
					textHeaderComponent={<>
						A survey has already been started for that business by another user. Please{` `}
						<Link
							href={externalLinks.absContactUs}
							isExternal
							onClick={() => recordRumCustomEventWithPageId(
								RumCustomEvent.contactUsClicked,
								getRumAttributes()
							)}
						>
							contact us
						</Link>{' '}
						to reset a survey.
					</>}
					variation='error'
					alertProps={errorBannerProps}
					errorCode={activeErrorCode} />
			)}
			{delegateeExpiredError && (
				<AlertComponent
					title='Delegation Expired'
					onDismiss={() => setDelegateeExpiredError(false)}
					textHeaderComponent={<>
						Your delegation has expired. Delegations need to be accepted within 24 hours from the time they are given.{' '}
						Please speak to this business' owner or administrator to renew your delegation.
						<br />
						<br />
						If you are still having trouble please{' '}
						<Link
							href={externalLinks.absContactUs}
							isExternal
							onClick={() => recordRumCustomEventWithPageId(
								RumCustomEvent.contactUsClicked,
								getRumAttributes()
							)}
						>
							contact us
						</Link>{' '}
						for assistance.
					</>}
					variation='error'
					alertProps={errorBannerProps}
					errorCode={activeErrorCode} />
			)}
		</View><Grid
			className='manual-authorise-container'
			marginTop={{ base: '32px', medium: '48px' }}
		
			templateColumns={gridMainBreakPoints}
		>
				<View column={selectBusinessPageWrapperColumns}>
					<Flex
						gap={48}
						direction='column'
						alignSelf='flex-start'
						margin='0px'
						padding='0px'
					>
						<View maxWidth={
							420
						}>
						<Breadcrumb
							text='Back to accounting software selection'
							onClick={() => navigate('/authorisation', {
								state: { addingBusiness: true },
								replace: true,
							})} />
							</View>
						<Heading
							level={1}
							testId='manual-authorise-heading'
							className='manual-authorise-heading'
						>
							Continue to your survey
						</Heading>
						<form onSubmit={handleSubmit(handleAuthSubmit)}>
							<Text className="manual-auth-text" margin='20px 0'>Please enter your reference number</Text>

							<Controller
								name='businessId'
								control={control}
								defaultValue=''
								render={({ field }) => (
									<TextField
										{...field}
										className='manual-authorise-input'
										testId='manual-authorise-input'
										label={<Flex alignItems='center' gap='4px'>
											<Text className="manual-auth-text">Reference number</Text>
											<InputLabelTooltip
												title={<Text className="manual-auth-text" id='business-id-tooltip' role='tooltip'>
													Your reference number is two letters (AT or MU)
													followed by eight numbers such as 'AT12345678'. This
													can be found in the email or letter you received
													asking you to complete your survey.
												</Text>}
												helpTextFor='Business ID'
												placement='top-start'
												arrow />
										</Flex>}
										hasError={!!errors.businessId}
										errorMessage={errors.businessId?.message}
										isDisabled={GetDspAccessTokenResponse.loading ||
											ClaimObligationResponse.loading} />
								)}
								rules={{
									required: 'Reference number is required',
									pattern: {
										value: /^[\da-z]{10}$/i,
										message: 'Reference number must be two letters followed by eight numbers',
									},
								}} />
							<Flex direction='column' alignItems='flex-start' marginTop={48}>
								<Button
									className='manual-authorise-submit-btn'
									testId='manual-authorise-submit-btn'
									variation='primary'
									type='submit'
									name='submit-btn'
									isLoading={GetDspAccessTokenResponse.loading ||
										ClaimObligationResponse.loading}
									loadingText='Loading'
									marginTop='16px'
								>
									Submit
								</Button>
								{inlineError && <InlineError />}
							</Flex>
						</form>
						<Text marginTop='40px'>
							Need help? Visit{' '}
							<Link
								href={externalLinks.absContactUs}
								isExternal
								testId='claim-obligation-help-link'
								onClick={() => recordRumCustomEventWithPageId(
									RumCustomEvent.contactUsClicked,
									getRumAttributes()
								)}
							>
								ABS Business Reporting help.
							</Link>
						</Text>
					</Flex>
				</View>

				<View column={heroImageWrapperColumns}
					width="100%"
					display={{ base: 'none', medium: 'block' }}>
					<Image
						style={{
							display: 'inline-block',
							height: '100%',
							width: 'auto',
							objectFit: 'cover',
							flexGrow: 0,
						}}
						src={ManualAuthHero}
						alt="A woman in an apron standing and typing on a laptop next to an open notebook on the phone" />
				</View>
			</Grid></>
  ) : (
    <ErrorPage selectedError={0} />
  );
};

export default ManualAuthorisation;
