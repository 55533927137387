/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import {
  AlertProps,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Text,
  TextField,
  View,
  useBreakpointValue,
} from '@aws-amplify/ui-react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';

import { recordRumCustomEventWithPageId, recordRumError } from 'services/awsRum';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import { externalLinks } from 'lib/externalLinks';
import { InputLabelTooltip } from 'components/InputLabelTooltip/InputLabelTooltip';
import { ClaimObligation } from 'models';
import { CLAIM_OBLIGATION } from 'apollo/queries/claimObligation';
import { REVOKE_BUSINESS } from 'apollo/mutations/revokeBusiness';
import { InlineError } from 'components/InlineError/InlineError';
import { formatAbn } from 'utils/formatAbn';
import { getDsp } from 'utils/dspIndex';
import { setActiveBusinessAfterClaim } from 'utils/setActiveBusinessAfterClaim';
import { ApiErrorMsg } from 'enums/ApiErrorMsg';
import { checkErrorMessages, getErrorCode } from 'utils/errorHandling';
import { Breadcrumb } from 'components/Breadcrumb/Breadcrumb';

import ClaimObligationHero from 'assets/claim-obligation.jpg';
import { ReactComponent as SvgChevronRight } from 'assets/icon-chevron-right.svg';
import { ReactComponent as SvgAbsLogo } from 'assets/logo-ABS.svg';
import { ReactComponent as SavingIconSvg } from 'assets/icon-loading-save.svg';

import './ClaimObligationPage.css';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import { getRumAttributes } from 'utils/getRumAttributes';
import { gridBreakPoints } from 'utils/gridBreakPoints';

type ClaimObligationForm = {
  businessId: string;
};

type ClaimObligationState = {
  businessName: string;
  businessAbn: string;
  dsp: string;
  organisationId: string;
  role: string;
};

// This only ever goes up, as a workaround to make sure Apollo never uses a cached
// call for claimObligation even if the other variables are the same.
let updateCounter = 0;

/**
 * @deprecated only use this in tests, so you don't break something
 */
export const resetUpdateCounterForTest = () => {
  updateCounter = 0;
};

const errorBannerProps: AlertProps = {
  testId: 'claim-obligation-error-banner',
  isDismissible: true,
  padding: '24px 0',
};

const ClaimObligationPage = () => {
  const {
    getValues,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ClaimObligationForm>();

  const navigate = useNavigate();
  const businessDetails = (useLocation().state as ClaimObligationState) ?? {};

  const dsp = getDsp(businessDetails.dsp);

  const [matchError, setMatchError] = useState(false);
  const [claimedError, setClaimedError] = useState(false);
  const [inlineError, setInlineError] = useState(false);
  const [activeErrorCode, setActiveErrorCode] = useState<number>();

  const [shouldClaim, setShouldClaim] = useState(false);
  const [pegaDetails, setPegaDetails] = useState({ abn: '', name: '' });

  /**
   * True only when the actual claim has succeeded, not when the check with claim:false succeeds.
   *
   * claimObligationResponse is used for both the initial check and the actual claim, so its success
   * status does not reliably indicate that the actual claim has completed.
   */
  const [hasClaimedObligation, setHasClaimedObligation] = useState(false);

  const [callClaimObligation, claimObligationResponse] = useLazyQuery<ClaimObligation>(CLAIM_OBLIGATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      updateCounter += 1;
      if (response.claimObligation.success) {
        setPegaDetails({
          abn: response.claimObligation.pegaAbn ?? '',
          name: response.claimObligation.pegaBusinessName ?? '',
        });
        if (shouldClaim) {
          setActiveBusinessAfterClaim(claimObligationResponse.variables?.organisationId);
          setHasClaimedObligation(true);
          navigate('/dashboard');
        } else {
          // show second flow
          return setShouldClaim(true);
        }
      }

      const errorMessages = response.claimObligation.errorMessages ?? [];
      if (checkErrorMessages(errorMessages, ApiErrorMsg.invalidClaim)) {
        setMatchError(true);
        setActiveErrorCode(getErrorCode(errorMessages, ApiErrorMsg.invalidClaim));
      } else if (checkErrorMessages(errorMessages, ApiErrorMsg.alreadyClaimed)) {
        setClaimedError(true);
        setActiveErrorCode(getErrorCode(errorMessages, ApiErrorMsg.alreadyClaimed));
      } else {
        setInlineError(true);
      }
    },
    onError: (error) => {
      recordRumError(error);
      setInlineError(true);
    },
  });

  const [callRevokeBusiness] = useMutation(REVOKE_BUSINESS, {
    variables: { dspProvider: businessDetails.dsp, organisationId: businessDetails.organisationId },
  });

  const claimObligationVariables = {
    organisationId: businessDetails.organisationId,
    role: businessDetails.role,
    dsp: businessDetails.dsp,
    updateCounter,
  };

  const trialClaim = (data: ClaimObligationForm) => {
    setInlineError(false);
    setClaimedError(false);
    setMatchError(false);
    setActiveErrorCode(undefined);
    callClaimObligation({
      variables: {
        ...claimObligationVariables,
        noObligation: {
          claim: false,
          pegaProviderId: data.businessId,
        },
      },
    });
  };

  const attemptClaim = () => {
    setInlineError(false);
    callClaimObligation({
      variables: {
        ...claimObligationVariables,
        noObligation: {
          claim: true,
          pegaProviderId: getValues().businessId,
        },
      },
    });
  };

  useEffect(() => {
    if (!hasClaimedObligation) {
      // Wrapped to ensure event argument is not accidentally interpreted as mutation function options
      const wrappedRevokeBusiness = () => {
        callRevokeBusiness();
      };

      window.addEventListener('beforeunload', wrappedRevokeBusiness);
      return () => {
        window.removeEventListener('beforeunload', wrappedRevokeBusiness);
      };
    }
  }, [callRevokeBusiness, hasClaimedObligation]);

  const pageWrapperColumns = useBreakpointValue({
    base: '2/14',
    medium: '2/10',
    large: '2/9',
    xl: '2/10',
  }) as string;

  const heroImageWrapperColumns = useBreakpointValue({
    base: '2/14',
    medium: '10/14',
    large: '10/14',
    xl: '10/14',
  }) as string;

  const gridMainBreakPoints = useBreakpointValue(gridBreakPoints as Record<string, unknown>) as string;

  return (
    <>
      <View>
        {matchError && (
          <AlertComponent
            title="Entered reference number does not match our records"
            textHeaderComponent={
              <>
                Your reference number is two letters (AT or MU) followed by eight numbers such as 'AT12345678'. This can
                be found in the email or letter you received asking you to complete your survey.
                <br />
                <br />
                If you are still having trouble please{' '}
                <Link
                  href={externalLinks.absContactUs}
                  isExternal
                  onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
                >
                  contact us
                </Link>{' '}
                for assistance.
              </>
            }
            variation="error"
            alertProps={errorBannerProps}
            errorCode={activeErrorCode}
            onDismiss={() => setMatchError(false)}
          />
        )}
        {claimedError && (
          <AlertComponent
            title="Reference number already claimed"
            textHeaderComponent={
              <>
                Another user has already started a survey for your business, either for this quarter or earlier. Please
                {` `}
                <Link
                  href={externalLinks.absContactUs}
                  isExternal
                  onClick={() => recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())}
                >
                  contact us
                </Link>{' '}
                to reset the survey.
              </>
            }
            variation="error"
            alertProps={errorBannerProps}
            errorCode={activeErrorCode}
            onDismiss={() => setClaimedError(false)}
          />
        )}
      </View>
      <Grid className="claim-obligation-container" margin="0px" templateColumns={gridMainBreakPoints}>
        <View column={pageWrapperColumns}>
          <Flex gap={48} direction="column" alignSelf="flex-start" margin="0px" padding="0px">
            <Flex
              className="claim-obligation-content"
              gap={48}
              direction="column"
              marginTop={{ base: '32px', medium: '48px' }}
            >
              {!shouldClaim && (
                <Breadcrumb
                  text="Back to select business"
                  onClick={() => {
                    callRevokeBusiness();
                    navigate('/authorisation', { state: { addingBusiness: true } });
                  }}
                />
              )}
              <Heading level={1} className="claim-obligation-header" testId="claim-obligation-header">
                We don't recognise the business you have selected
              </Heading>
              {!shouldClaim ? (
                <>
                  {/* PAGE 1 --- START */}

                  <Flex direction="column" gap="24px">
                    <Text className="claim-obligation-body-text" testId="claim-obligation-body-text">
                      The Australian Business Number (ABN) of the business you are trying to authorise does not match
                      our records.
                    </Text>
                    <View>
                      <Heading
                        level={2}
                        className="claim-obligation-body-heading"
                        testId="claim-obligation-body-heading"
                      >
                        ABN
                      </Heading>
                      <Text className="claim-obligation-body-text">
                        {businessDetails?.businessAbn ? formatAbn(businessDetails.businessAbn) : ''}
                      </Text>
                    </View>
                    <View>
                      <Heading
                        level={2}
                        className="claim-obligation-body-heading"
                        testId="claim-obligation-body-heading"
                      >
                        Business Name
                      </Heading>
                      <Text className="claim-obligation-body-text">{businessDetails.businessName}</Text>
                    </View>
                  </Flex>
                  <form onSubmit={handleSubmit(trialClaim)}>
                    <Text className="claim-obligation-body-text" marginBottom={16}>
                      Please enter your reference number
                    </Text>
                    <Controller
                      name="businessId"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="claim-obligation-input"
                          testId="claim-obligation-input"
                          label={
                            <Flex alignItems="center" gap="4px">
                              <Text>Reference number</Text>
                              <InputLabelTooltip
                                title={
                                  <Text id="business-id-tooltip" role="tooltip">
                                    Your reference number is two letters (AT or MU) followed by eight numbers such as
                                    'AT12345678'. This can be found in the email or letter you received asking you to
                                    complete your survey.
                                  </Text>
                                }
                                helpTextFor="Business ID"
                                placement="top-start"
                                arrow
                              />
                            </Flex>
                          }
                          hasError={!!errors.businessId}
                          errorMessage={errors.businessId?.message}
                        />
                      )}
                      rules={{
                        required: 'Reference number is required',
                        pattern: {
                          value: /^[\da-z]{10}$/i,
                          message: 'Reference number must be two letters followed by eight numbers',
                        },
                      }}
                    />
                    <Flex direction="column" alignItems="flex-start" marginTop={48}>
                      <Button
                        className="claim-obligation-submit-btn"
                        testId="claim-obligation-submit-btn"
                        variation="primary"
                        type="submit"
                        name="submit-btn"
                        isLoading={claimObligationResponse.loading}
                        loadingText="Loading"
                      >
                        Submit
                      </Button>
                      {inlineError && <InlineError />}
                    </Flex>
                  </form>
                  {/* PAGE 1 --- END */}
                </>
              ) : (
                <>
                  {/* PAGE 2 --- START */}
                  <Flex direction="column" gap="40px">
                    <Flex gap="20px" padding="0px">
                      <Grid
                        column="1/3"
                        templateColumns={{ base: '1fr', large: '1fr 1fr' }}
                        templateRows={{ base: 'repeat(4, auto)', large: 'repeat(2, auto)' }}
                        width="100%"
                        gap={{ base: '0px 20px', large: '16px 20px' }}
                        autoFlow="column"
                      >
                        <View marginBottom={{ base: '16px', large: '0px' }}>
                          <Heading className="claim-obligation-comparison" level={2} style={{ alignSelf: 'top' }}>
                            Business the ABS is asking you to report for
                          </Heading>
                        </View>
                        <View marginBottom={{ base: '24px', large: '0px' }}>
                          <BusinessDetailComparison Logo={SvgAbsLogo} abn={pegaDetails.abn} name={pegaDetails.name} />
                        </View>
                        <View marginBottom={{ base: '16px', large: '0px' }}>
                          <Heading className="claim-obligation-comparison" level={2} style={{ verticalAlign: 'top' }}>
                            Business you attempted to link to ABS Business Reporting
                          </Heading>
                        </View>
                        <View>
                          <BusinessDetailComparison
                            Logo={dsp.authoriseDspIcon}
                            abn={businessDetails.businessAbn}
                            name={businessDetails.businessName}
                          />
                        </View>
                      </Grid>
                    </Flex>
                    <Flex direction="column" gap="8px">
                      <Text className="claim-obligation-body-text">
                        We are looking for information for the specific ABN we have listed.
                      </Text>
                      <Text className="claim-obligation-body-text">
                        This may be an ABN associated with a trust. Please select the business associated with the ABN
                        shown.
                      </Text>
                      <Flex alignItems="center" gap="10px">
                        <NavLink
                          className="claim-obligation-back-link"
                          data-testid="claim-obligation-try-another-link"
                          to="/authorisation"
                          state={{ addingBusiness: true }}
                          onClick={() => callRevokeBusiness()}
                        >
                          Try another business
                        </NavLink>
                        <SvgChevronRight />
                      </Flex>
                    </Flex>
                    <Flex direction="column" gap="8px">
                      <Text className="claim-obligation-body-text">
                        If the business associated with the ABN listed is no longer operating, you may not need to
                        report.
                      </Text>
                      <Flex alignItems="center" gap="10px">
                        <Link
                          className="claim-obligation-back-link"
                          data-testid="claim-obligation-let-us-know-link"
                          href={externalLinks.absContactUs}
                          isExternal
                          onClick={() =>
                            recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())
                          }
                        >
                          Contact us to let us know
                        </Link>
                        <SvgChevronRight />
                      </Flex>
                    </Flex>
                    <Flex direction="column" gap="8px">
                      <Text className="claim-obligation-body-text">
                        If you are sure the business you attempted to link is the same as the business we asked you to
                        complete a survey for, you can continue below.
                      </Text>
                      <Flex alignItems="center" gap="10px">
                        <Link
                          className="claim-obligation-back-link"
                          data-testid="claim-obligation-back-link"
                          onClick={attemptClaim}
                        >
                          Continue with the business you attempted to link
                        </Link>
                        {claimObligationResponse.loading ? (
                          <SavingIconSvg className="saving-rotate-icon" />
                        ) : (
                          <SvgChevronRight />
                        )}
                      </Flex>
                      {inlineError && (
                        <InlineError errorMessage="Sorry, something went wrong. Please try again later." />
                      )}
                    </Flex>
                    <Text marginTop="auto" marginBottom="48px">
                      Need help?{' '}
                      <Link
                        href={externalLinks.absContactUs}
                        isExternal
                        testId="claim-obligation-help-link"
                        onClick={() =>
                          recordRumCustomEventWithPageId(RumCustomEvent.contactUsClicked, getRumAttributes())
                        }
                      >
                        Contact us!
                      </Link>
                    </Text>
                  </Flex>
                  {/* PAGE 2 --- END */}
                </>
              )}
            </Flex>
          </Flex>
        </View>
        <View
          column={heroImageWrapperColumns}
          width="100%"
          marginTop={{ base: '32px', medium: '48px' }}
          display={{ base: 'none', medium: 'block' }}
        >
          <Image
            style={{
              display: 'inline-block',
              height: '100%',
              width: 'auto',
              objectFit: 'cover',
              flexGrow: 0,
            }}
            src={ClaimObligationHero}
            alt="A woman in an apron standing and typing on a laptop next to an open notebook on the phone"
          />
        </View>
      </Grid>
    </>
  );
};

const BusinessDetailComparison = ({
  Logo,
  abn,
  name,
}: {
  Logo: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  abn: string;
  name: string;
}) => (
  <Grid templateRows="1fr" gap="24px" style={{ flexBasis: '100%' }}>
    <Flex alignItems="center">
      <View height="80px" width="80px">
        <Logo height="80px" width="auto" />
      </View>
      <Flex direction="column" alignSelf="flex-start">
        <View>
          <Heading className="claim-obligation-body-heading">ABN</Heading>
          <Text className="claim-obligation-body-text" testId="comparison-abn">
            {formatAbn(abn)}
          </Text>
        </View>
        <View>
          <Heading className="claim-obligation-body-heading">Business Name</Heading>
          <Text className="claim-obligation-body-text" testId="comparison-name">
            {name}
          </Text>
        </View>
      </Flex>
    </Flex>
  </Grid>
);

export default ClaimObligationPage;
