/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Flex, Heading, Text, View, VisuallyHidden } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import 'utils/animation.css';

import ReportProgressCapsule from 'components/ReportProgressCapsule/ReportProgressCapsule';
import ReportProgressText from 'components/ReportProgressText/ReportProgressText';
import { ReportStatus } from 'enums/ReportStatus';
import { ReportPeriodFormat, ReportQuarterFormat } from 'utils/ReportPeriodFormat';
import { ChosenReport, Delegate, GetReportReport } from 'models';
import { useUserInfo } from 'lib/userInfoHook';
import handleGeneratePdf from 'utils/handleGeneratePDF';
import { mapGraphQLReportToLocal } from 'utils/mapGraphQLReportToLocal';
import { recordRumCustomEvent } from 'services/awsRum';
import { getRumAttributes } from 'utils/getRumAttributes';
import { RumCustomEvent } from 'enums/RumCustomEvent';
import useDspApiErrorToast from 'hooks/useDspApiErrorToast';
import './ReportSummary.css';
import { LockState } from 'models/GraphQL/LockState';
import { DAYNUM_MONTHNAME_YEAR } from 'utils/dayjsFormats';
import { syncingDspBusinessNamesState, savingLocalAnswersStatus } from 'apollo/states/operationsInProgress';
import { useReactiveVar } from '@apollo/client';
import useGetReport, { responseDataOrErrors } from 'utils/useGetReport';
import { isCurrentStatus } from 'components/Dashboard/reportStatusUtil';
import { withTimeout } from 'utils/withTimeout';
import { InlineError } from 'components/InlineError/InlineError';
import { useLaunchReport } from 'utils/useLaunchReport';
import DelegateModal from 'components/DelegateModal/DelegateModal';
import RevokeDelegateModal from 'components/RevokeDelegateModal/RevokeDelegateModal';
import { DelegateCapsule } from 'components/ReportProgressCapsule/DelegateCapsule';
import { GetReportTimerLabel } from './GetReportTimerLabel';

dayjs.extend(advancedFormat);
interface ReportSummaryProps {
  name: string;
  dueDate: number;
  lastEditedDate: number;
  status: ReportStatus;
  cycleStartDate: number;
  cycleEndDate: number;
  reportData: ChosenReport;
  preCache?: boolean;
  loadingReportId: string;
  setLoadingReportId: (userReportId: string) => void;
  lockState?: LockState;
  canDelegate?: boolean;
  delegates?: Delegate[];
  maxDelegatesReached?: boolean;
  isBusinessOwner?: boolean;
}

const ReportSummary = (props: ReportSummaryProps) => {
  const isSyncingDspBusinessNames = useReactiveVar(syncingDspBusinessNamesState);
  const {
    name,
    status,
    dueDate,
    lastEditedDate,
    cycleStartDate,
    cycleEndDate,
    reportData,
    lockState,
    loadingReportId,
    setLoadingReportId,
    preCache,
    canDelegate = false,
    maxDelegatesReached = false,
    delegates = [],
    isBusinessOwner = false,
  } = props;
  const userInfo = useUserInfo();
  const launchReport = useLaunchReport(reportData);
  const [toastToShow, showToastOpenIfDSPError, clearDspApiToast] = useDspApiErrorToast();

  const dueDateObj = dayjs(dueDate);

  const shouldGeneratePdf = status === ReportStatus.Submitted || status === ReportStatus.SubmittedFeedback;

  const [callGetReport, getReportQuery, setGetReportCaching] = useGetReport(reportData, preCache, !shouldGeneratePdf);
  // Resolved value from callGetReport promise, to process as soon as !isSyncingDspBusinessNames
  const [fetchedReportValue, setFetchedReportValue] = useState<GetReportReport>();

  const userReportId = reportData.userReportId ?? '';

  // Stored in a ref so that the latest value is available in the promise callbacks
  const loadingThisReport = useRef(loadingReportId.length && loadingReportId === userReportId);

  const [showDelegateModal, setShowDelegateModal] = useState<boolean>(false);
  const [showRevokeDelegateModal, setShowRevokeDelegateModal] = useState<boolean>(false);

  // Effects for when a report starts launching (may be this report)
  useEffect(() => {
    const thisReportWasLoading = loadingThisReport.current;
    const thisReportIsLoading = loadingReportId.length && loadingReportId === userReportId;
    // Ignore if not changed
    if (thisReportIsLoading === thisReportWasLoading) return;

    // and update the ref for the handleLaunchOrReviewOrPdf callback
    loadingThisReport.current = thisReportIsLoading;

    if (!thisReportIsLoading) {
      // was loading and now is not

      // switch the getReport back to 'pre-cache' mode
      setGetReportCaching();
      // and clear any cached response from getReport so it will not be launched
      setFetchedReportValue(undefined);
    }
  }, [loadingReportId, userReportId, setGetReportCaching]);

  // tracks thrown getReport errors
  const [inlineError, setInlineError] = useState(false);

  const [timedOut, setTimedOut] = useState(false);

  // We can launch immediately on "Try again" click if the getReport call succeeded after timeout
  // but for (business?) reasons we change to a "Launch report" button and make the user click again.
  // This will be true when we are preventing launch in this way.
  const [preventedLaunch, setPreventedLaunch] = useState(false);

  const reportIsLocked = lockState?.lock && !lockState.selfLock && isCurrentStatus(status);

  // If we are still syncing DSP business names when the getReport finishes, we cache the response
  // and show loading until the business names check completes
  const isLoading = getReportQuery.loading || Boolean(isSyncingDspBusinessNames && fetchedReportValue);

  /**
   * Either launch or download the report.
   *
   * @param fetchedReport a defined report value from the GetReport response.
   */
  const processFetchedReport = (fetchedReport: GetReportReport) => {
    const localReportData = mapGraphQLReportToLocal(reportData, fetchedReport, shouldGeneratePdf, userInfo);

    if (shouldGeneratePdf) {
      recordRumCustomEvent(RumCustomEvent.downloadPdfClicked, {
        pageId: window.location.pathname,
        ...getRumAttributes(),
      });
      handleGeneratePdf(true, localReportData);
    } else {
      // Non-pdf workflow
      recordRumCustomEvent(
        RumCustomEvent.reportOpen,
        getRumAttributes({ reportId: localReportData.localChosenReport.userReportId })
      );
      launchReport(localReportData);
    }

    // Finished with reportPromiseResponse, clear it to prevent possible bugs
    setFetchedReportValue(undefined);
  };

  // Effect to process the report response and launch the report or generate PDF,
  // but not until isSyncingDspBusinessNames completes.
  // (this counts on the response being cleared when another report is launching).
  useEffect(() => {
    if (isSyncingDspBusinessNames || !fetchedReportValue || preventedLaunch) return;

    processFetchedReport(fetchedReportValue);

    // Only want this to trigger when the business names check completes, a
    // response comes back, or we stop preventing launch.
    // All the other dependencies do not change after initial
    // load so are not included in the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSyncingDspBusinessNames, fetchedReportValue, preventedLaunch]);

  const handleLaunchOrReviewOrPdf = () => {
    // Need to set this regardless of preventedLaunch in case another report was clicked
    // after "Try again" on this report.
    setLoadingReportId(userReportId);

    if (preventedLaunch) {
      // User clicked button again after we had prevented launch from "Try again"
      // so flip it back to false (will trigger launch from the useEffect)
      setPreventedLaunch(false);
      return;
    }

    savingLocalAnswersStatus('idle');
    setInlineError(false);
    setTimedOut(false);
    clearDspApiToast();
    withTimeout(callGetReport(), 60000)
      .then((response) => {
        if (!loadingThisReport.current) {
          // This should not occur since the promise from callGetReport should
          // not resolve, but is a cheap extra safety net.
          console.log('Skipped report launch because another report is loading');
          return;
        }

        // Error response can be processed immediately, regardless of isSyncingDspBusinessNames
        const dataOrErrors = responseDataOrErrors(response);
        if (!dataOrErrors.succeeded) {
          if (!showToastOpenIfDSPError(dataOrErrors.errorMessages)) {
            setInlineError(true);
          }
          return;
        }

        // Store the response so it can be used for launch when all conditions are met
        setFetchedReportValue(dataOrErrors.data);

        // If the user clicked "Try again", we switch back to a "Launch report" button that they have to click
        // instead of launching the report that we have retrieved.
        // Note: this could be a report that was loaded successfully just after timeout, or one that was just
        //       fetched from a new request.
        // Other note: dev thinks this feature should be deleted, and just launch the report if we have it.
        if (buttonText === 'Try again') setPreventedLaunch(true);
      })
      .catch((error) => {
        if (!loadingThisReport.current) {
          // The user already switched to loading a different report, so skip showing
          // any error state.
          console.log('Skipped error states from failed getReport because another report is loading');
          return;
        }

        setInlineError(true);
        if (error?.message === 'Promise timed out') {
          setTimedOut(true);

          // On timeout, we could still get a success response back from the getReport call.
          // Sets the getReport back to caching mode so it will save the response and use it
          // when clicking 'Try again'.
          // Also switches to the non-loading default response object, so the error will display.
          setGetReportCaching();
        }
      });
  };

  const getReportError = !!(
    !getReportQuery ||
    getReportQuery.error ||
    getReportQuery.data?.getReport?.success === false ||
    // timeout is treated as a getReportError because we present it to the user that way
    timedOut
  );
  const retryable = !isLoading && (getReportError || inlineError);

  const loadingText = shouldGeneratePdf ? 'Preparing PDF' : 'Loading survey';
  const buttonText = (() => {
    if (retryable) return 'Try again';
    if (reportIsLocked) return 'Survey in use';
    if (isLoading) return loadingText;
    return shouldGeneratePdf ? 'Download PDF' : 'Launch survey';
  })();

  const launchDisabled = reportIsLocked || status === ReportStatus.UnderReview || isLoading;

  const showReportActionButton = [
    ReportStatus.New,
    ReportStatus.Edit,
    ReportStatus.UnderReview,
    ReportStatus.Submitted,
    ReportStatus.SubmittedFeedback,
  ].includes(status);

  return (
    <Card variation="elevated" className="report-card" testId="report-card-container">
      {canDelegate && isBusinessOwner && (
        <DelegateModal isOpen={showDelegateModal} onClose={() => setShowDelegateModal(false)} reportName={name} />
      )}
      {isBusinessOwner && (
        <RevokeDelegateModal
          isOpen={showRevokeDelegateModal}
          close={() => setShowRevokeDelegateModal(false)}
          delegates={delegates}
        />
      )}
      <Flex
        direction={{ base: 'column', large: 'row' }}
        gap={{ base: 'var(--amplify-space-small)', large: 'var(--amplify-space-large)' }}
      >
        <Flex
          className="report-top-info"
          direction="column"
          wrap="wrap"
          justifyContent="space-between"
          gap="var(--amplify-space-medium)"
          grow="1"
        >
          <Flex direction="column" wrap="wrap" gap="12px">
            <Heading level={3} className="report-heading" testId="report-heading">
              {name}
            </Heading>
            <Text className="report-text" testId="report-period">
              {ReportPeriodFormat(cycleStartDate, cycleEndDate)} ({ReportQuarterFormat(cycleStartDate, cycleEndDate)})
            </Text>
          </Flex>
          <Flex>
            <ReportProgressCapsule status={status} dueDate={dueDate} />
            {maxDelegatesReached && (
              <DelegateCapsule
                onClick={
                  isBusinessOwner
                    ? () => {
                        setShowRevokeDelegateModal(true);
                      }
                    : undefined
                }
              />
            )}
          </Flex>
          <Flex className="report-bottom-info" alignItems="start" row="2" justifyContent="space-between" wrap="wrap">
            <div className="progress-container">
              <View className="progress-text-wrapper">
                <View marginLeft="auto" textAlign="left" testId="report-status-details">
                  <ReportProgressText status={status} dueDate={dueDate} dateSubmitted={lastEditedDate} />
                </View>
              </View>
            </div>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          textAlign={{ base: 'left', large: 'right' }}
          wrap="wrap"
          gap={{ base: 'var(--amplify-space-large)', large: 'var(--amplify-space-xl)' }}
          basis="content"
        >
          <View>
            <Heading level={4} className="report-date-heading" testId="report-due-heading">
              Survey due
            </Heading>
            <Text className="report-date" testId="report-date">
              {dueDateObj.format(DAYNUM_MONTHNAME_YEAR)}
            </Text>
          </View>
          {(showReportActionButton || canDelegate) && (
            <Flex direction="column" justifyContent="end" width="100%">
              {showReportActionButton && (
                <>
                  <Button
                    className="report-action-btn"
                    testId="report-action-btn"
                    width="100%"
                    minWidth={{ base: 'none', large: '192px' }}
                    variation="primary"
                    disabled={launchDisabled}
                    aria-disabled={launchDisabled}
                    onClick={() => {
                      if (retryable) {
                        recordRumCustomEvent(
                          RumCustomEvent.reportGetRetry,
                          getRumAttributes({ providerId: reportData.dspProvider, reportId: reportData.userReportId })
                        );
                      }
                      handleLaunchOrReviewOrPdf();
                    }}
                    // Using the below instead of 'isLoading={getReportQuery.loading}' because
                    //   Amplify Button doesnt apply disabled class if loading prop is present
                    {...(isLoading && { isLoading: true })}
                    loadingText={loadingText}
                  >
                    {buttonText}
                    <VisuallyHidden> for {name}</VisuallyHidden>
                  </Button>
                  {isLoading && <GetReportTimerLabel />}
                  {getReportError && (!isLoading || timedOut) && (
                    <div className="long-text fade-in">
                      <InlineError errorMessage="We couldn't load this survey. Please try again later." />
                    </div>
                  )}
                </>
              )}
              {canDelegate && isBusinessOwner && (
                <Button
                  className="report-action-btn"
                  testId="report-action-btn"
                  width="100%"
                  minWidth={{ base: 'none', large: '192px' }}
                  border=" 2px solid #326297"
                  gap="4px"
                  color="#0F487C"
                  fontSize="16px"
                  fontWeight="600"
                  lineHeight="24px"
                  height="48px"
                  padding="12px"
                  onClick={() => setShowDelegateModal(true)}
                >
                  Delegate
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      {toastToShow}
    </Card>
  );
};

export default ReportSummary;
