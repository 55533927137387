import { Button, Flex, Grid, Heading, Input, Label, Text, TextField, VisuallyHidden } from '@aws-amplify/ui-react';
import { Dialog } from '@mui/material';
import CustomAmplifyTheme from 'assets/CustomAmplifyTheme';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import { ReactComponent as CloseButtonSvg } from 'assets/icon-close-button.svg';
import './DelegateModal.scss';
import { useContext, useState } from 'react';
import { useSetDelegation } from 'utils/useSetDelegation';
import { InlineError } from 'components/InlineError/InlineError';
import ToastNotification from 'components/ToastNotification/ToastNotification';
import { RefreshDashboardData } from 'pages/Dashboard/DashboardPage';

export interface DelegateModalProps {
  isOpen: boolean;
  reportName: string;
  onClose: () => void;
  providerId?: string;
}

const DelegateModal = ({ isOpen, onClose, reportName, providerId }: DelegateModalProps) => {
  const refreshData = useContext(RefreshDashboardData);

  const [delegateeFirstName, setDelegateeFirstName] = useState<string>('');
  const [delegateeLastName, setDelegateeLastName] = useState<string>('');
  const [delegateeEmail, setDelegateeEmail] = useState<string>('');

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [successToast, setSuccessToast] = useState<boolean>(false);

  const [submitDelegation, loading] = useSetDelegation([
    {
      email: delegateeEmail,
      firstName: delegateeFirstName,
      lastName: delegateeLastName,
    },
  ]);

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDelegateeFirstName(String(e.currentTarget.value));
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDelegateeLastName(String(e.currentTarget.value));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDelegateeEmail(String(e.currentTarget.value));
  };

  const handleSubmission = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!delegateeFirstName || !delegateeEmail || !delegateeLastName) return;

    submitDelegation()
      .then((res) => {
        if (res.data === null || res.data === undefined) {
          setErrorMessage("We couldn't assign the delegatee. Please try again later.");
          return;
        }

        if (res.data.delegation?.errorMessages?.length || !res.data.delegation.success) {
          if (res.data.delegation?.errorMessages?.[0].errorCode === 3) {
            setErrorMessage('You cannot assign more than one delegatee.');
          } else setErrorMessage("We couldn't assign the delegatee. Please try again later.");
          return;
        }
        onClose();
        setSuccessToast(true);
        refreshData?.();
      })
      .catch(() => {
        setErrorMessage("We couldn't assign the delegatee. Please try again later.");
      });
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <CustomAmplifyTheme isModal>
          <Flex
            className="delegate-modal-container"
            alignItems={{ base: 'center', large: 'start' }}
            as="form"
            onSubmit={handleSubmission}
            testId="delegate-modal-form"
          >
            <Flex justifyContent="space-between" width="100%">
              <Heading level={2} fontWeight="600" fontSize="24px" lineHeight="32px" letterSpacing="-0.02em">
                Delegate {reportName}
              </Heading>
              <Button testId="delegate-modal-close-btn" onClick={onClose} padding="0" border="none">
                <CloseButtonSvg />
                <VisuallyHidden>Close delegate modal</VisuallyHidden>
              </Button>
            </Flex>
            <Flex direction="column" gap="16px" width="100%">
              <TextField
                label="First name of delegate"
                width={{ base: '100%', large: '328px' }}
                paddingBottom="22px"
                gap="4px"
                value={delegateeFirstName}
                onChange={handleFirstNameChange}
                required
                testId="delegate-modal-first-name"
                name="delegate-first-name"
              />
              <TextField
                label="Last name of delegate"
                width={{ base: '100%', large: '328px' }}
                paddingBottom="22px"
                gap="4px"
                value={delegateeLastName}
                onChange={handleLastNameChange}
                required
                testId="delegate-modal-last-name"
                name="delegate-last-name"
              />
              <Flex direction="column" gap="4px" paddingBottom="22px">
                <Label htmlFor="delegate-email">Email address of delegate</Label>
                <Input
                  id="delegate-email"
                  type="email"
                  name="delegate-email"
                  width={{ base: '100%', large: '328px' }}
                  value={delegateeEmail}
                  onChange={handleEmailChange}
                  required
                  testId="delegate-modal-email"
                />
              </Flex>
            </Flex>
            <Grid templateColumns="1fr">
              <AlertComponent
                templateRows="1fr"
                templateColumns="0rem repeat(12, 1fr) 0rem"
                column="1/-1"
                contentColumn="2/-2"
                columnGap="var(--amplify-space-small)"
                textHeaderComponent={
                  <>
                    <Text as="span" className="delegate-modal-alert-text">
                      The ABS will not email the delegate. Please ensure you inform them directly and advise them of the
                      Reference number found on the email or letter you received requesting you complete the survey. You
                      may wish to copy the following paragraph to email to them:
                    </Text>
                    <br />
                    <br />
                    <Text as="span" className="delegate-modal-alert-text">
                      I would like to delegate an ABS survey to you. Please go to www.abs.gov.au/businessreporting and
                      log in or create an account. Once you have logged in, select Continue without accounting software,
                      and enter the reference number {providerId || ''} to complete the survey.
                    </Text>
                  </>
                }
                variation="warning"
                disableAutoScroll
              />
            </Grid>
            <Button
              variation="primary"
              width={{ base: '100%', large: '87px' }}
              height="48px"
              type="submit"
              testId="delegate-modal-submit-btn"
              isLoading={Boolean(loading)}
              loadingText="Submit"
            >
              Submit
            </Button>
            {errorMessage && <InlineError errorMessage={errorMessage} />}
          </Flex>
        </CustomAmplifyTheme>
      </Dialog>
      <ToastNotification
        success
        title="Delegatee Assigned"
        text="Delegatee was successfully assigned."
        open={successToast}
      />
    </>
  );
};

export default DelegateModal;
