/* eslint-disable react/jsx-boolean-value */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Button, Card, Flex, Heading, Image, Text } from '@aws-amplify/ui-react';
import dayjs from 'dayjs';
import { userContactDetails } from 'apollo/states/UserContactDetails';
import { doLogout } from 'utils/doLogout';
import { chosenReport } from 'apollo/states/ChosenReport';
import Feedback from 'components/Feedback/Feedback';
import { currentReportData } from 'apollo/states/CurrentReportData';
import { PDFButton } from 'utils/PDFButton';
import { ReactComponent as SuccessIconSvg } from 'assets/confirmation-tick-icon.svg';
import { ReactComponent as LogoutIconSvg } from 'assets/icon-logout-blue.svg';
import { ReactComponent as HomeIconSvg } from 'assets/icon-home.svg';
import ABSLogo from 'assets/logo-ABS.svg';
import './Confirmation.css';
import { DAYNUM_MONTHNAME_YEAR } from 'utils/dayjsFormats';
import InsightsWidget from 'components/InsightsWidget/InsightsWidget';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const Confirmation = () => {
  const { oktaAuth } = useOktaAuth();

  const { firstName, lastName, email } = useReactiveVar(userContactDetails);
  const { dataItems } = useReactiveVar(currentReportData);
  const { submitted, reportExtendedDateRangeText, organisationName, submittedDatetime } = useReactiveVar(chosenReport);

  const formattedDate = submittedDatetime ? dayjs(submittedDatetime).format(DAYNUM_MONTHNAME_YEAR) : '';
  const feedbackDataItem = dataItems.find((item) => item.topic === 'ASPFeedback' && item.action === 'STAR');

  const focusRef = useRef<HTMLHeadingElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focusRef.current != null) {
      focusRef.current.focus();
    }
  }, []);

  const navigate = useNavigate();

  const handleClickDashboard = () => {
    navigate('/dashboard');
  };

  const handleClickLogOut = () => {
    navigate('/');
    doLogout(oktaAuth);
  };

  return (
    <Flex className="confirmation-wrapper" direction="column" gap="48px" marginTop={{ base: '52px', medium: '48px' }}>
      <Flex alignItems={{ base: 'flex-start' }} direction={{ base: 'column', medium: 'row' }} gap={{ base: '12px' }}>
        <SuccessIconSvg className="confirmation-tick-icon" data-testid="tick-icon" />
        <Heading
          level={1}
          ref={focusRef}
          className="confirmation-heading"
          testId="confirmation-heading"
          role="status"
          aria-live="assertive"
        >
          Your survey has been submitted
        </Heading>
      </Flex>
      <Text className="submission-text" testId="confirmation-text" maxWidth="880px">
        Thank you for completing your ABS survey. A record of your submission is below.
      </Text>
      {feedbackDataItem && (
        <Flex direction="column">
          <Feedback feedbackDataItem={feedbackDataItem} pdfButtonRef={buttonRef} />
        </Flex>
      )}
      <Card
        className="submission-receipt-card"
        variation="outlined"
        borderColor="#EFF0F0"
        borderStyle="solid"
        borderWidth="1px"
        borderRadius="16px"
        padding={{ base: '16px', medium: '32px 24px' }}
      >
        <Flex
          direction={{ base: 'column', medium: 'row' }}
          alignItems={{ base: 'flex-start', medium: 'center' }}
          gap={{ base: '24px' }}
        >
          <Image
            testId="submission-logo"
            src={ABSLogo}
            alt="Australian Bureau of Statistics Logo"
            height="40px"
            width="40px"
          />
          <Heading
            fontSize={{ base: '28px', medium: '32px' }}
            className="submission-logo-heading"
            testId="submission-logo-heading"
          >
            Australian Bureau of Statistics
          </Heading>
          {submitted && (
            <Flex
              direction="column"
              alignItems={{ base: 'flex-start', medium: 'flex-end' }}
              marginLeft={{ base: '0px', medium: 'auto' }}
              width={{ base: '100%', medium: 'auto' }}
            >
              <PDFButton submitted={submitted} buttonRef={buttonRef} />
            </Flex>
          )}
        </Flex>
        {/* Only render when submitted so that we don't generate a "submitted" PDF if the user somehow gets to this page
            with a non-submitted report. */}

        <Flex gap="24px" direction="column">
          <SubmissionHeader title="Submission details" />
          <SubmissionInfo title="Business name" value={organisationName || ''} />
          <SubmissionInfo title="Quarter" value={`${reportExtendedDateRangeText || ''}`} />
          <SubmissionInfo title="Submitted by" value={`${firstName} ${lastName}`} />
          <SubmissionInfo title="Submitted on" value={formattedDate} />
        </Flex>
        <Flex className="submission-section" direction="column">
          <SubmissionHeader title="Contact information" />
          <SubmissionInfo title="Email" value={email} secondary />
        </Flex>
      </Card>
      <InsightsWidget />
      <Flex direction={{ base: 'column', medium: 'row-reverse' }} marginBottom="48px">
        <Button
          role="button"
          width={{ base: '100%', medium: '205px' }}
          padding="12px"
          gap="4px"
          backgroundColor="#326297"
          color="#FFFFFF"
          fontSize="16px"
          fontWeight="600"
          lineHeight="24px"
          onClick={handleClickDashboard}
          name="Back to dashboard"
          testId="back-to-dashboard-confirmation"
        >
          <HomeIconSvg />
          Back to dashboard
        </Button>
        <Button
          role="button"
          width={{ base: '100%', medium: '118px' }}
          padding="12px"
          gap="4px"
          fontSize="16px"
          fontWeight="600"
          lineHeight="24px"
          color="#326297"
          border="solid 2px #326297"
          onClick={handleClickLogOut}
          name="Log out"
          testId="logout-confirmation"
        >
          <LogoutIconSvg /> Log Out
        </Button>
      </Flex>
    </Flex>
  );
};

interface SubmissionHeaderProps {
  title: string;
}
const SubmissionHeader = (props: SubmissionHeaderProps) => {
  const { title } = props;
  return (
    <Heading className="submission-info-header" testId="submission-heading">
      {title}
    </Heading>
  );
};

interface SubmissionInfoProps {
  title: string;
  value: string;
  secondary?: boolean;
}
const SubmissionInfo = (props: SubmissionInfoProps) => {
  const { title, value, secondary = false } = props;
  return (
    <Flex
      justifyContent="flex-start"
      className="submission-flex"
      direction={{ base: 'column', medium: 'row' }}
      gap={{ base: '8px ' }}
    >
      <Text
        className="submission-title-text"
        testId="submission-detail-title"
        width={{ base: '125px', medium: '213.33px', large: '245.33px' }}
      >
        {title}
      </Text>
      <Text className={secondary ? 'submission-text' : 'submission-info-value'} testId="submission-detail-value">
        {value}
      </Text>
    </Flex>
  );
};

export default Confirmation;
