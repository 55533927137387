import { ErrorMessage } from 'models';
import { ApiErrorMsg } from 'enums/ApiErrorMsg';

export const providerDetailsNotFound = 'Internal System Error: Unable to find provider details for';

/**
 * Check whether errorMessages has a specified error, based on errorType, and sometimes message
 *
 * @param errorMessages to search in for the error
 * @param errorType type of error to find, matching error.errorType property from API
 * @param checkText text to check for at start of error.message, only checked for INTERNAL_SYSTEM_ERROR errorType
 * @returns true if an error is found, otherwise false
 */
export function checkErrorMessages(errorMessages: ErrorMessage[], errorType: string, checkText?: string) {
  // TODO: fix error signature so that checkText is only allowed for INTERNAL_SYSTEM_ERROR only, probably by using
  //       an options object that can represent the difference.
  return Boolean(findErrorMessage(errorMessages, errorType, checkText));
}

/**
 * Find a matching ErrorMessage in errorMessages.
 *
 * @param errorMessages to search in for the error
 * @param errorType type of error to find, matching error.errorType property from API
 * @param checkText text to check for at start of error.message, only checked for INTERNAL_SYSTEM_ERROR errorType
 * @returns the first matching ErrorMessage from errorMessages if found, otherwise undefined
 */
export function findErrorMessage(errorMessages: ErrorMessage[] | undefined, errorType: string, checkText?: string) {
  // TODO: fix error signature so that checkText is only allowed for INTERNAL_SYSTEM_ERROR only, probably by using
  //       an options object that can represent the difference.
  if (!errorMessages) return undefined;

  if (errorType === ApiErrorMsg.internalSystemError && checkText) {
    return errorMessages.find((x) => x.message.startsWith(checkText));
  }

  return errorMessages.find((x) => x.errorType === errorType);
}

/**
 * Get the error code from an error of the specified type, if present in errorMessages
 *
 * @param errorMessages to search in
 * @param errorType to check against error.errorType
 * @returns error.errorCode if a matching error was found, otherwise undefined
 */
export function getErrorCode(errorMessages: ErrorMessage[], errorType: string): number | undefined {
  return errorMessages.find((e) => e.errorType === errorType)?.errorCode;
}
