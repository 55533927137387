import { AccessDetail, ChosenReport, ReportOverview } from 'models';
import { getDsp } from 'utils/dspIndex';
import {
  ReportEndFormat,
  ReportExtendedQuarterFormat,
  ReportPeriodFormat,
  ReportTitleQuarterFormat,
  ReportQuarterFormat,
} from 'utils/ReportPeriodFormat';

// import { DownloadAllReportsButton } from './DownloadAllReportsButton';
export const reportDataForFactory =
  (selectedOrganisation: AccessDetail | undefined) =>
  (report: ReportOverview): ChosenReport => ({
    dspProvider: selectedOrganisation?.dsp,
    organisationId: selectedOrganisation?.organisationDetails?.organisationId,
    singleUserAccess: report.singleUserAccess,
    userReportId: report.userReportId,
    // FIXME: dspAbn is different in the backend than PEGA ABN, so this is misleading
    dspAbn: selectedOrganisation?.organisationDetails?.providerDetails.currentPEGAABN,
    reportName: report.name,
    reportPeriod: ReportPeriodFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    reportEnd: ReportEndFormat(report.spRefPeriodEndDate),
    reportStart: ReportEndFormat(report.spRefPeriodStartDate),
    reportDateRangeText: ReportQuarterFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    reportDateRangeTitleText: ReportTitleQuarterFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    reportExtendedDateRangeText: ReportExtendedQuarterFormat(report.spRefPeriodStartDate, report.spRefPeriodEndDate),
    organisationName: selectedOrganisation?.organisationDetails?.providerDetails.name,
    reportDueDate: report?.dueDate,
    reportLastEditedDate: report.lastEditedDate,
    dspDisplayName: getDsp(selectedOrganisation?.dsp).displayName,
    isUsingDsp: getDsp(selectedOrganisation?.dsp).isUsingDspProvider,
  });
