import { ReportStatus } from 'enums/ReportStatus';

export const isCurrentStatus = (status: string) =>
  [ReportStatus.New.toLowerCase(), ReportStatus.Edit.toLowerCase(), ReportStatus.UnderReview.toLowerCase()].includes(
    status.toLowerCase()
  );

export const isPreviousStatus = (status: string) =>
  [
    ReportStatus.NotSubmitted.toLowerCase(),
    ReportStatus.Submitted.toLowerCase(),
    ReportStatus.SubmittedFeedback.toLowerCase(),
  ].includes(status.toLowerCase());
