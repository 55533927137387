/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';
import { Flex, Grid, Heading, TabItem, Tabs, Text, View, VisuallyHidden } from '@aws-amplify/ui-react';
import { useReactiveVar } from '@apollo/client';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { dashboardReportTabState } from 'apollo/states/operationsInProgress';

import { AccessDetail, Delegate } from 'models';
import BusinessSelect from 'components/BusinessSelect/BusinessSelect';
import InsightsWidget from 'components/InsightsWidget/InsightsWidget';
import { useUserInfo } from 'lib/userInfoHook';
import { getDsp } from 'utils/dspIndex';
import { canUserDelegateNoDspOrganisation, doesUserOwnOrganisation } from 'utils/canUserDelegateNoDspOrganisation';
import RevokeDelegateSection from '../RevokeDelegateSection/RevokeDelegateSection';
import { isCurrentStatus, isPreviousStatus } from './reportStatusUtil';
import { DashboardBusinessInformation } from './DashboardBusinessInformation';
import { ReportSummaries } from './ReportSummaries';

import './Dashboard.css';

interface DashboardProps {
  data?: AccessDetail[];
}

const Dashboard = ({ data }: DashboardProps) => {
  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const userInfo = useUserInfo();
  // reportId of the report that is launching, if any (getReport in progress)
  const [loadingReportId, setLoadingReportId] = useState('');

  const selectedOrganisation = data?.find(
    (business) => business.organisationDetails?.organisationId === currentBusinesses.activeBusiness?.id
  );

  const canDelegate = canUserDelegateNoDspOrganisation(userInfo, selectedOrganisation);
  const delegates: Delegate[] =
    (selectedOrganisation?.organisationDetails?.providerDetails?.delegates as Delegate[]) ?? [];

  const isOwner = doesUserOwnOrganisation(userInfo, selectedOrganisation);

  const maxDelegatesReached = Boolean(selectedOrganisation?.organisationDetails?.providerDetails.maxDelegatees);

  const hasDelegatesToRevoke = delegates.length > 0;
  // Consider using providerReportOverviews()[currentBusinesses.activeBusiness?.id] instead

  // sort before we pass into report summaries
  selectedOrganisation?.providerReports?.sort((a, b) => b.dueDate - a.dueDate);

  const currentReports = selectedOrganisation?.providerReports?.filter((report) => isCurrentStatus(report.status));
  const pastReports = selectedOrganisation?.providerReports?.filter((report) => isPreviousStatus(report.status));

  // Pre-fetching of the top reports so they are in apollo cache
  const preCacheReports = currentReports?.slice(0, 3).map((report) => report.userReportId);

  // Setting the tab state to ensure it is the default value of 'current'
  useEffect(() => {
    dashboardReportTabState('current');
  }, []);

  return (
    <Flex gap="48px" direction="column">
      <Grid
        className="dashboard-wrapper"
        templateColumns="1fr"
        marginTop={{ base: '0px', medium: '48px' }}
        borderRadius="var(--amplify-radii-large)"
      >
        <Grid
          templateColumns={{ base: '1fr', xl: '1fr 1fr' }}
          className="dashboard-header-wrapper"
          gap="var(--amplify-space-xxl)"
        >
          <Flex
            direction={{ base: 'column', xl: 'row' }}
            gap="24px"
            column={{ base: '1/-1' }}
            justifyContent="space-between"
          >
            <View as="section">
              <Heading fontWeight="600" fontSize="40px" lineHeight="48px" testId="dashboard-header-heading" level={1}>
                {!!selectedOrganisation?.organisationDetails?.providerDetails.name && (
                  <VisuallyHidden>Dashboard for </VisuallyHidden>
                )}
                {selectedOrganisation?.organisationDetails?.providerDetails.name || 'Dashboard'}
              </Heading>
              {getDsp(selectedOrganisation?.dsp).showDspBusinessName &&
                selectedOrganisation?.organisationDetails?.dspBusinessName !== '' && (
                  <Heading className="dashboard-dsp-name-heading" testId="dsp-name-heading" level={2}>
                    {selectedOrganisation?.organisationDetails?.dspBusinessName}
                  </Heading>
                )}
            </View>
            <View
              testId="dashboard-header-business-select-wrapper"
              maxWidth={{ base: 'none', medium: '288px' }}
              minWidth={{ base: 'none', medium: '208px' }}
              width="100%"
            >
              <BusinessSelect />
            </View>
          </Flex>
          <View className="dashboard-abn-wrapper" column="1/-1">
            <Heading
              color="#326297"
              fontWeight="600"
              fontSize="24px"
              lineHeight="32px "
              testId="dashboard-header-heading"
              level={2}
            >
              Welcome, {userInfo?.firstName}
            </Heading>
            <Text color="#304050" fontWeight="400" fontSize="16px" lineHeight="24px" testId="dashboard-abn-text">
              Welcome to your dashboard. Here you can access your surveys and change the business you are reporting for.
            </Text>
            {hasDelegatesToRevoke && isOwner && <RevokeDelegateSection delegates={delegates} />}
          </View>
        </Grid>
      </Grid>
      <Grid className="business-info" templateColumns="1fr" rowGap="24px">
        <Heading level={2} fontWeight="600" fontSize="32px" lineHeight="40px" letterSpacing="-0.02em">
          Business information
        </Heading>
        <DashboardBusinessInformation selectedOrganisation={selectedOrganisation} />
      </Grid>
      <div className="tab-container">
        <Heading marginBottom="25px" level={2} fontSize="28px" fontWeight="600">
          Quarterly Business Indicators Survey
        </Heading>

        <Tabs testId="report-tabs" borderStyle="solid" borderWidth="0px 0px 4px" borderColor="#DCDEE0">
          <TabItem
            title="Current surveys"
            testId="tab-current-reports"
            onClick={() => dashboardReportTabState('current')}
            className="dashboard-tab"
          >
            <ReportSummaries
              reports={currentReports}
              selectedOrganisation={selectedOrganisation}
              preCacheReports={preCacheReports}
              loadingReportId={loadingReportId}
              setLoadingReportId={setLoadingReportId}
              canDelegate={canDelegate}
              delegates={delegates}
              maxDelegatesReached={maxDelegatesReached}
              isBusinessOwner={isOwner}
              emptyMessage="You have no surveys to complete"
            />
          </TabItem>
          <TabItem
            title="Past surveys"
            testId="tab-submitted-reports"
            onClick={() => dashboardReportTabState('past')}
            className="dashboard-tab"
          >
            <ReportSummaries
              reports={pastReports}
              selectedOrganisation={selectedOrganisation}
              preCacheReports={preCacheReports}
              loadingReportId={loadingReportId}
              setLoadingReportId={setLoadingReportId}
              canDelegate={canDelegate}
              delegates={delegates}
              maxDelegatesReached={maxDelegatesReached}
              isBusinessOwner={isOwner}
              emptyMessage="You have no past surveys to view"
            />
          </TabItem>
        </Tabs>
      </div>
      <View className="insights-widget-wrapper">
        <InsightsWidget />
      </View>
    </Flex>
  );
};

export default Dashboard;
