/* istanbul ignore file */
export const currencyFormat = Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

type InputSanitizer = (input: string) => string;
const sanitizationPipeline =
  (...sanitizers: InputSanitizer[]) =>
  (input: string) =>
    sanitizers.reduce((acc, sanitizer) => {
      const newAcc = sanitizer(acc);
      return newAcc;
    }, input);

const withOnlyNumberCharacters: InputSanitizer = (val) => val.replace(/[^0-9.-]/g, '');
const withoutLeadingZeros: InputSanitizer = (val) =>
  val.replace(/^-?0+(?!$)/, (match) => (match.startsWith('-') ? '-' : ''));
const withCommas: InputSanitizer = (val) => val.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
const withoutDecimal: InputSanitizer = (val) => val.split('.', 1)[0];
const withOnlyTwelveCharacters: InputSanitizer = (val) => val.substring(0, 12);

export const asSanitizedCurrencyValue = sanitizationPipeline(withOnlyNumberCharacters, withoutLeadingZeros);
export const asSanitizedCurrencyInput = sanitizationPipeline(asSanitizedCurrencyValue, withCommas);
export const asSanitizedCurrencyPaste = sanitizationPipeline(
  withOnlyNumberCharacters,
  withOnlyTwelveCharacters,
  withoutLeadingZeros,
  withoutDecimal,
  withCommas
);
