import { Button, Flex, Heading, Text, VisuallyHidden } from '@aws-amplify/ui-react';
import { Dialog } from '@mui/material';
import CustomAmplifyTheme from 'assets/CustomAmplifyTheme';
import { ReactComponent as CloseButtonSvg } from 'assets/icon-close-button.svg';
import { useContext, useMemo, useState } from 'react';
import { InlineError } from 'components/InlineError/InlineError';
import ToastNotification from 'components/ToastNotification/ToastNotification';
import { Delegate, RevokeBusinessResponse } from 'models';
import { useMutation, useReactiveVar } from '@apollo/client';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { REVOKE_BUSINESS } from 'apollo/mutations/revokeBusiness';
import { RefreshDashboardData } from 'pages/Dashboard/DashboardPage';

export interface DelegateModalProps {
  isOpen: boolean;
  delegates: Delegate[];
  close: () => void;
}

const RevokeDelegateModal = ({ isOpen, close, delegates }: DelegateModalProps) => {
  const refreshData = useContext(RefreshDashboardData);

  const currentBusinesses = useReactiveVar(authorisedBusinesses);
  const { activeBusiness } = currentBusinesses;
  const providerId = activeBusiness ? String(activeBusiness.providerId) : '';

  const [callRevokeBusiness, { loading }] = useMutation<RevokeBusinessResponse>(REVOKE_BUSINESS);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successToast, setSuccessToast] = useState<boolean>(false);

  const delegateInfo = useMemo(
    () =>
      delegates.map((delegate) => ({
        firstName: String(delegate.firstName) ?? '',
        lastName: String(delegate.lastName) ?? '',
        email: String(delegate.email) ?? '',
      })),
    [delegates]
  );

  const revokeDelegateeAccess = () => {
    callRevokeBusiness({
      variables: {
        dspProvider: activeBusiness?.dsp,
        organisationId: activeBusiness?.id,
        providerId,
        // Using a the first item int he delegateInfo array as there can only be one delegate at this point
        email: delegateInfo[0]?.email,
      },
      notifyOnNetworkStatusChange: true,
      onCompleted: (response) => {
        if (response.revokeBusiness.success && !response.revokeBusiness?.errorMessages?.length) {
          setSuccessToast(true);
          setErrorMessage('');
          close();
          refreshData?.();
        } else {
          setErrorMessage('We cannot revoke access right now. Please try again later.');
        }
      },
      onError: () => {
        setErrorMessage('We cannot revoke access right now. Please try again later.');
      },
    });
  };

  const handleClose = () => {
    if (!loading) close();
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} aria-labelledby="revoke-modal-heading">
        <CustomAmplifyTheme isModal>
          <Flex className="delegate-modal-container" alignItems={{ base: 'center', large: 'start' }}>
            <Flex justifyContent="space-between" width="100%">
              <Heading
                testId="revoke-delegate-header"
                id="revoke-modal-heading"
                level={2}
                fontWeight="600"
                fontSize="24px"
                lineHeight="32px"
                letterSpacing="-0.02em"
              >
                Revoke Delegation
              </Heading>
              <Button testId="revoke-delegate-modal-close-btn" onClick={handleClose} padding="0" border="none">
                <VisuallyHidden>Close Modal</VisuallyHidden>
                <CloseButtonSvg />
              </Button>
            </Flex>
            {delegates.length ? (
              <Text>
                You have delegated reporting for this business to{' '}
                {delegateInfo.map(({ firstName, lastName, email }) => `${firstName} ${lastName} (${email})`).join(', ')}{' '}
                and they can access these reports. Are you sure you want to revoke this delegation?
              </Text>
            ) : (
              <Text>
                There are no delegates to revoke at this time. Delegates need to manually register the business.
              </Text>
            )}
            <Flex justifyContent="flex-start" width="100%">
              {Boolean(delegates.length) && (
                <Button
                  variation="primary"
                  height="48px"
                  type="submit"
                  testId="revoke-delegate-submit-btn"
                  loadingText="Revoke Delegation"
                  onClick={revokeDelegateeAccess}
                  isLoading={loading}
                  borderRadius="8px"
                >
                  Revoke Delegation
                </Button>
              )}
              <Button
                height="48px"
                onClick={handleClose}
                fontWeight="600"
                lineHeight="24px"
                fontSize="16px"
                color="#0F487C"
                disabled={loading}
                testId="revoke-delegate-cancel-btn"
              >
                Cancel
              </Button>
            </Flex>
            {errorMessage && <InlineError errorMessage={errorMessage} />}
          </Flex>
        </CustomAmplifyTheme>
      </Dialog>
      <ToastNotification
        success
        title="Delegatee Access Has Been Revoked"
        text="Delegatee access was successfully revoked."
        open={successToast}
      />
    </>
  );
};

export default RevokeDelegateModal;
