/**
 * These correlate to error types in the back-end project, currently defined in
 * asp-deploy-repository-backend/lambdas/common/error-handling/error-messages/error-messages-builder.ts
 */

// FIXME: this should be identical to backend (copy-paste or common import)
//        so there is no ambiguity, but this version uses different keys.
//        rename the keys to match backend.
const errorTypes = {
  systemError: 'SYSTEM_ERROR',
  invalidClaimError: 'INVALID_CLAIM',
  noABNFoundForClaim: 'NO_ABN_FOR_CLAIM',
  alreadyClaimedError: 'ALREADY_CLAIMED',
  missingData: 'MISSING_DATA',
  validationError: 'VALIDATION_ERROR',
  dspError: 'DSP_ERROR',
  dataBaseError: 'DATABASE_ERROR',
  oktaError: 'OKTA_ERROR',
  unauthorized: 'UNAUTHORIZED',
  myobSsoError: 'MYOB_SSO_ERROR',
  reportLocked: 'REPORT_LOCKED',
  delegateeExpired: 'DELEGATEE_EXPIRED',
};

// FIXME: this looks like a subset of errorTypes, find purpose and document the
//        purpose of these different objects, make them readonly, unify the property
//        names to avoid confusion, and unify the types rather than duplicate definitions
//        (unless errorTypes above exists to show exactly what is in backend for reference,
//         in which case indicate the backend commit or release that it is copied from).
export const ApiErrorMsg = {
  internalSystemError: errorTypes.systemError,
  invalidClaim: errorTypes.invalidClaimError,
  noAbnFoundForClaim: errorTypes.noABNFoundForClaim,
  alreadyClaimed: errorTypes.alreadyClaimedError,
  requestFailed401: errorTypes.dspError,
  dspApiNoAbnError: errorTypes.missingData,
  dspApiErrorType: errorTypes.validationError,
  dspApiTimeoutType: errorTypes.dspError,
  dspApiLambaTimeout: errorTypes.dspError,
  dspApiUnauthorized: errorTypes.unauthorized,
  delegateeExpired: errorTypes.delegateeExpired,
};

export const dspApiErrorTypes = [
  ApiErrorMsg.dspApiErrorType,
  ApiErrorMsg.dspApiTimeoutType,
  ApiErrorMsg.dspApiLambaTimeout,
];
