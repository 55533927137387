/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { DefaultContext, MutationFunctionOptions, OperationVariables, useMutation } from '@apollo/client';
import { Dialog } from '@mui/material';
import { Button, Flex, Heading, Text, Image, View } from '@aws-amplify/ui-react';

import ReportButtonGroup from 'components/ReportComponents/ReportButtonGroup/ReportButtonGroup';
import RollupData from 'components/RollupData/RollupData';
import { InlineError } from 'components/InlineError/InlineError';
import { SUBMIT_REPORT } from 'apollo/mutations/submitReport';
import { latestSavedReportDataItems } from 'apollo/states/LatestSavedReportDataItems';
import { SubmitReportResponse, SaveReportResponse } from 'models';
import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { useTopicsWithMissingData } from 'utils/useTopicsWithMissingData';
import { getSaveReportOptions, SaveReportContext } from 'utils/getSaveReportOptions';
import { getRumAttributes } from 'utils/getRumAttributes';
import { recordRumCustomEvent, recordRumError } from 'services/awsRum';
import CustomAmplifyTheme from 'assets/CustomAmplifyTheme';
import { RumCustomEvent } from 'enums/RumCustomEvent';

import InfoIcon from 'assets/icon-info-primary.svg';
import './SubmitButtonWithModal.scss';
import { isSubmittingReport } from 'apollo/states/operationsInProgress';
import { PageRoutes } from 'enums/PageRoutes';
import { chosenReport } from 'apollo/states/ChosenReport';

import { ReactComponent as IconEditPencil } from 'assets/icon-pencil.svg';
import { isCurrentReportFullySaved, useCurrentReportFullySaved } from 'services/SaveReportManager/currentReportStatus';
import { useSaveReport } from 'utils/useSaveReport';
import { getMergedReportDataItems } from 'utils/getUpdatedReportDataItems';
import { savedReportDataItems } from 'apollo/states/SavedReportDataItem';

const SubmitButtonWithModal = () => {
  const navigate = useNavigate();
  const reportFullySaved = useCurrentReportFullySaved();
  const currentReportTopics = useCurrentReportTopics();
  const missingDataTopics = useTopicsWithMissingData();

  const [inlineError, setInlineError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [saveReport] = useSaveReport('SubmitButtonWithModal');

  const [callSubmit, trySubmit] = useMutation<SubmitReportResponse, OperationVariables, SaveReportContext>(
    SUBMIT_REPORT,
    {
      onCompleted: (
        data: SubmitReportResponse,
        // Note: SaveReportContext would be more correct than DefaultContext, but Apollo does not use the
        // provided context type in the definition for clientOptions
        // See the Apollo source code:
        //   https://github.com/apollographql/apollo-client/blob/main/src/react/hooks/useMutation.ts
        //   where clientOptions is cast to MutationOptions without the TContext type variable
        clientOptions?: MutationFunctionOptions<SaveReportResponse, OperationVariables, DefaultContext>
      ) => {
        if (data.submitReport.success) {
          // have to cast this because the apollo types do not use the context type variables properly
          const saveContext = clientOptions?.context as SaveReportContext | undefined;
          const updatesInSave = saveContext?.updatesInSave ?? { savedAnswers: [] };

          // compute the new server values after the submit (it is possible for items be saved in a submit)
          const serverSavedValues = getMergedReportDataItems(
            savedReportDataItems(),
            latestSavedReportDataItems(),
            updatesInSave
          );
          latestSavedReportDataItems(serverSavedValues);

          chosenReport({ ...chosenReport(), submitted: true, submittedDatetime: data.submitReport.submittedDatetime });
          isSubmittingReport(false);
          navigate(PageRoutes.ReportConfirmation.pathname, {
            replace: true,
            state: { from: PageRoutes.ReportReview.pathname },
          });
        }
      },
      onError: (error) => {
        recordRumError(error);
        setInlineError(true);
        isSubmittingReport(false);
      },
    }
  );

  const handleModalOpen = () => {
    setInlineError(false);
    setModalOpen(true);

    if (!isCurrentReportFullySaved()) {
      saveReport('handleModalOpen', 'Clicked Submit survey');
    }
  };

  const handleSubmit = async () => {
    isSubmittingReport(true);
    setInlineError(false);
    recordRumCustomEvent(RumCustomEvent.reportSubmit, getRumAttributes());

    const saveReportOptions = getSaveReportOptions('handleSubmit SubmitButtonWithModal');

    // Add the feedback rollup data items for submission
    // Need to add rollups to start of array due to BE logic check for '-1' or '-2' at [0]
    saveReportOptions.variables.reportDataObject?.reportDataItems?.unshift({
      dataItemId: '-1',
      responseUid: `ASP-${crypto.randomUUID()}`,
      userValue: JSON.stringify(RollupData()),
    });

    try {
      await callSubmit(saveReportOptions);
    } catch (error) {
      setInlineError(true);
    }
  };

  const getTopicPath = (searchTopic: string | undefined) =>
    currentReportTopics.find((topic) => topic.topic === searchTopic)?.path;

  const getTopicShortTitle = (searchTopic: string | undefined) =>
    currentReportTopics.find((topic) => topic.topic === searchTopic)?.topicShortTitle;

  return (
    <>
      <ReportButtonGroup
        nextButton={{
          text: 'Submit survey',
          link: 'report/confirmation',
          Callback: handleModalOpen,
          showIcon: true,
        }}
        showSyncButton
        exitButton={{
          text: 'Save and exit',
          link: '/dashboard',
          useSave: true,
          showIcon: true,
        }}
      />
      <Dialog
        open={modalOpen}
        className="submit-modal"
        PaperProps={{
          'aria-labelledby': 'dialog-heading',
        }}
      >
        <CustomAmplifyTheme isModal>
          <View className="submit-modal-content">
            <Flex direction="column" justifyContent="space-between" alignItems="center" gap="24px">
              <div className="modal-icon-container">
                {trySubmit.loading ? (
                  <div className="dot-flashing" />
                ) : (
                  <Image alt="" src={InfoIcon} style={{ width: '36px' }} />
                )}
              </div>
              <Heading id="dialog-heading" className="submit-modal-heading" testId="submit-modal-heading" level={1}>
                Confirm submission?
              </Heading>
              {missingDataTopics.length > 0 && (
                <View className="missing-info-warnings" testId="missing-info-warnings">
                  <View className="missing-info-row">
                    <Text fontWeight="700" textAlign="center">
                      {missingDataTopics.length} section{missingDataTopics.length > 1 ? 's are' : ' is'} still missing
                      information
                    </Text>
                  </View>
                  {/* TODO: Refactor with DuplicateMappingsComponent.tsx */}
                  {missingDataTopics.map((warning) => (
                    <View className="missing-info-row" key={`${warning}-warning`}>
                      <Text>{getTopicShortTitle(warning)}</Text>
                      <Link
                        to={getTopicPath(warning) ?? ''}
                        className="missing-data-warning-link"
                        test-id="missing-data-warning-link"
                      >
                        <Flex alignItems="center" gap="10px">
                          <IconEditPencil className="detailed-topic-title-link-icon" aria-label={`Edit ${warning}`} />
                          <Text className="edit-text">Edit section</Text>
                        </Flex>
                      </Link>
                    </View>
                  ))}
                </View>
              )}
              <Text className="submit-modal-text">
                After clicking ‘Submit survey’ you will no longer be able to make changes to the data you are providing.
              </Text>
              <Flex className="modal-button-group">
                <Button
                  testId="confirm-submit-button primary-button-radii-override"
                  variation="primary"
                  onClick={handleSubmit}
                  isDisabled={trySubmit.loading || !reportFullySaved}
                >
                  Submit survey
                </Button>
                <Button isDisabled={trySubmit.loading} onClick={() => setModalOpen(false)}>
                  Back to survey
                </Button>
              </Flex>
              {inlineError && <InlineError styles="submit-modal-error" />}
            </Flex>
          </View>
        </CustomAmplifyTheme>
      </Dialog>
    </>
  );
};

export default SubmitButtonWithModal;
