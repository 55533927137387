import { Flex, View, Text } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';
import useCurrentReportTopics from 'apollo/states/utils/useCurrentReportTopics';
import { ReactComponent as IconEditPencil } from 'assets/icon-pencil.svg';
import { getTopicByName, TopicName } from 'models/LocalState/TopicFullDetail';
import { duplicateMappingTopics } from 'apollo/states/DuplicateMappingTopics';

export const DuplicateMappingsComponent = ({ topics }: { topics: TopicName[] }) => {
  const currentReportTopics = useCurrentReportTopics();

  const handleClick = (clickedTopic: TopicName) => {
    const latestDuplicateMappings = duplicateMappingTopics();
    duplicateMappingTopics(latestDuplicateMappings.filter((topicName) => topicName !== clickedTopic));
  };

  // TODO: refactor this with the empty topics warning on report submit (submitButtonWithModal.tsx)
  return (
    <>
      {topics.map((topicName) => (
        <View className="missing-info-row" key={`${topicName}-warning`}>
          <Text>{getTopicByName(currentReportTopics, topicName)?.topicShortTitle}</Text>
          <Link
            to={getTopicByName(currentReportTopics, topicName)?.path ?? ''}
            className="missing-data-warning-link"
            test-id="missing-data-warning-link"
            onClick={() => handleClick(topicName)}
          >
            <Flex alignItems="center" gap="10px">
              <IconEditPencil className="detailed-topic-title-link-icon" aria-label={`Edit ${topicName}`} />
              <Text className="edit-text">Edit section</Text>
            </Flex>
          </Link>
        </View>
      ))}
    </>
  );
};
