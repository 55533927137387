import { useBreakpointValue as realUseBreakpointValue } from '@aws-amplify/ui-react';
import { Breakpoint } from '@aws-amplify/ui-react/dist/types/primitives/types/responsive';

/**
 * Experimental wrapped useBreakpoingValue that returns a known type.
 *
 * So you don't need to do any manual casting, as long as the values of the object/array
 * are the output type you expect.
 *
 * @see useBreakpoingValue
 */
export const useBreakpointValue = <T>(
  values: Record<string, T> | T[],
  defaultBreakpoint?: Breakpoint,
  propKey?: string
): T => realUseBreakpointValue(values, defaultBreakpoint, propKey) as T;
