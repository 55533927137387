import { Flex, Text, Button, View } from '@aws-amplify/ui-react';
import { ReactComponent as DelegatedIcon } from 'assets/icon-delegated.svg';

interface DelegateCapsuleProps {
  /**
   * Provide an onClick function to render as a button,
   * or undefined to render as a plain div.
   */
  onClick?: () => void;
}

const content = (
  <Flex alignItems="center" justifyContent="center" direction="row" gap={8} padding={8}>
    <DelegatedIcon />
    <Text testId="report-progress-capsule-text" color="#326297">
      Delegated
    </Text>
  </Flex>
);

const sharedAttributes = { className: 'report-progress-capsule-wrapper', border: 'none', backgroundColor: '#F3F7FB' };

export const DelegateCapsule = ({ onClick }: DelegateCapsuleProps) =>
  onClick ? (
    <Button {...sharedAttributes} onClick={onClick}>
      {content}
    </Button>
  ) : (
    <View {...sharedAttributes}>{content}</View>
  );
