/* istanbul ignore file */

/**
 * Converts the given string to sentence case (first letter uppercase, all others lowercase).
 *
 * Note that this will **lowercase everything**, including proper nouns and initialisms.
 *
 * @param {string} text - string to convert
 * @returns {string} - sentence-case copy of text
 */
export const toSentenceCase = (text: string): string => {
  if (text) {
    const trimmedText = text.trim();
    return trimmedText.charAt(0).toUpperCase() + trimmedText.slice(1).toLowerCase();
  }
  return '';
};

export const toTitleCase = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
