import { Flex, Loader, Text } from '@aws-amplify/ui-react';
import ReportSummary from 'components/ReportSummary/ReportSummary';
import { ReportStatus } from 'enums/ReportStatus';
import { AccessDetail, Delegate, ReportOverview } from 'models';
import { reportDataForFactory } from './reportDataForFactory';

interface ReportSummariesBaseProps {
  // props for report data
  selectedOrganisation?: AccessDetail;
  reports?: ReportOverview[];

  // props for loading and pre-caching
  preCacheReports?: string[];
  loadingReportId: string;
  setLoadingReportId: (id: string) => void;

  // props related to delegation
  canDelegate: boolean;
  delegates: Delegate[];
  maxDelegatesReached: boolean;
  isBusinessOwner: boolean;
}

interface ReportSummariesInnerProps extends ReportSummariesBaseProps {
  reports: ReportOverview[];
}

interface EmptyReportSummariesProps {
  emptyMessage: string;
}

interface ReportSummariesProps extends ReportSummariesBaseProps, EmptyReportSummariesProps {}

const LoadingReportSummaries = () => (
  <Flex
    direction="column"
    className="tab-current-reports-container fade-in"
    style={{
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Loader aria-label="Loading..." height="64px" width="64px" style={{ cursor: 'not-allowed' }} />
  </Flex>
);

const EmptyReportSummaries = ({ emptyMessage }: EmptyReportSummariesProps) => (
  <Text className="empty-report-list">{emptyMessage}</Text>
);

/**
 * A list of ReportSummary components to display in a dashboard tab.
 */
const ReportSummariesInner = ({
  reports,
  selectedOrganisation,
  preCacheReports,
  loadingReportId,
  setLoadingReportId,
  canDelegate,
  delegates,
  maxDelegatesReached,
  isBusinessOwner,
}: ReportSummariesInnerProps) => {
  const reportDataFor = reportDataForFactory(selectedOrganisation);

  return (
    <Flex direction="column" className="tab-current-reports-container fade-in">
      {reports.map((report) => (
        <ReportSummary
          name={report.name}
          lastEditedDate={report.lastEditedDate}
          cycleStartDate={report.spRefPeriodStartDate}
          cycleEndDate={report.spRefPeriodEndDate}
          dueDate={report.dueDate}
          status={report.status as ReportStatus}
          reportData={reportDataFor(report)}
          key={report.userReportId}
          preCache={preCacheReports?.includes(report.userReportId)}
          loadingReportId={loadingReportId}
          setLoadingReportId={setLoadingReportId}
          lockState={report.singleUserAccess}
          canDelegate={canDelegate}
          delegates={delegates}
          maxDelegatesReached={maxDelegatesReached}
          isBusinessOwner={isBusinessOwner}
        />
      ))}
    </Flex>
  );
};

/**
 * A list of ReportSummary components to display in a dashboard tab, with empty and loading states.
 */
export const ReportSummaries = ({
  reports,
  selectedOrganisation,
  preCacheReports,
  loadingReportId,
  setLoadingReportId,
  canDelegate,
  delegates,
  maxDelegatesReached,
  isBusinessOwner,
  emptyMessage,
}: ReportSummariesProps) => {
  if (!reports) return <LoadingReportSummaries />;
  if (!reports.length) return <EmptyReportSummaries emptyMessage={emptyMessage} />;

  return (
    <ReportSummariesInner
      reports={reports}
      selectedOrganisation={selectedOrganisation}
      preCacheReports={preCacheReports}
      loadingReportId={loadingReportId}
      setLoadingReportId={setLoadingReportId}
      canDelegate={canDelegate}
      delegates={delegates}
      maxDelegatesReached={maxDelegatesReached}
      isBusinessOwner={isBusinessOwner}
    />
  );
};
