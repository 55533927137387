/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Card, Flex, Heading, Text, Image, Button, Link, Grid, useBreakpointValue } from '@aws-amplify/ui-react';
import { useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { businessSelectStyles, CustomDropdownIndicator, Control } from 'lib/BusinessSelectComponents';
import { CLAIM_OBLIGATION } from 'apollo/queries/claimObligation';
import { authorisedBusinesses } from 'apollo/states/AuthorisedBusinesses';
import { ClaimObligation } from 'models';
import { InlineError } from 'components/InlineError/InlineError';
import { AlertComponent } from 'components/AlertComponent/AlertComponent';
import { externalLinks } from 'lib/externalLinks';
import { ApiErrorMsg } from 'enums/ApiErrorMsg';
import { checkErrorMessages, getErrorCode } from 'utils/errorHandling';
import { setActiveBusinessAfterClaim } from 'utils/setActiveBusinessAfterClaim';

import myobLogo from 'assets/logo-myob.svg';
import { ReactComponent as ConnectBusinessIcon } from 'assets/icon-connect-business.svg';

import './SelectBusinessPage.css';
import { gridBreakPoints } from 'utils/gridBreakPoints';
import GoBackButton from 'utils/goBackButton';

interface SelectOptionType {
  value: '';
  label: '';
}

interface SelectBusinessState {
  newOrganisations: { organisationId: string; businessName: string }[];
  dsp: string;
}

// Workaround to simulate api call variables changing on re-submission of same business
// Updating the counter in the call's onCompleted callback
let updateCounter = 0;

const SelectBusinessPage = () => {
  const navigate = useNavigate();
  // Get authorised business ids to filter out connected businesses
  const currentBusinesses = authorisedBusinesses();
  const authorisedBusinessIds = currentBusinesses.authorisedBusinesses.map((business) => business.id);
  // Extract the DSP and the list of businesses from router state
  const newOrganisations = (useLocation().state as SelectBusinessState)?.newOrganisations ?? [];
  // Filter out businesses that are already connected
  const newBusinesses = newOrganisations.filter((business) => !authorisedBusinessIds.includes(business.organisationId));

  const dsp = (useLocation().state as SelectBusinessState)?.dsp ?? '';

  const selectOptions = newBusinesses.map((business) => ({
    value: business.organisationId,
    label: business.businessName,
  }));

  let dspLogo = '';
  switch (dsp.toLocaleLowerCase()) {
    case 'myob':
      dspLogo = myobLogo;
      break;
    default:
  }

  const [selectedBusiness, setSelectedBusiness] = useState<SelectOptionType | undefined>(
    selectOptions.length === 1 ? (selectOptions[0] as SelectOptionType) : undefined
  );

  const [inlineError, setInlineError] = useState(false);
  const [myobSsoError, setMyobSsoError] = useState(false);
  const [dspUnauthError, setDspUnauthError] = useState(false);
  const [activeErrorCode, setActiveErrorCode] = useState<number>();

  const [claimObligation, claimObligationResponse] = useLazyQuery<ClaimObligation | undefined>(CLAIM_OBLIGATION, {
    fetchPolicy: 'no-cache',
    onCompleted: (response) => {
      updateCounter += 1;
      const errorMessages = response?.claimObligation.errorMessages ?? [];
      if (response?.claimObligation.success) {
        setActiveBusinessAfterClaim(claimObligationResponse.variables?.organisationId);
        navigate('/dashboard');
      } else if (checkErrorMessages(errorMessages, ApiErrorMsg.requestFailed401)) {
        setMyobSsoError(true);
        setActiveErrorCode(getErrorCode(errorMessages, ApiErrorMsg.requestFailed401));
      } else if (checkErrorMessages(errorMessages, ApiErrorMsg.dspApiUnauthorized)) {
        setDspUnauthError(true);
        setActiveErrorCode(getErrorCode(errorMessages, ApiErrorMsg.dspApiUnauthorized));
      } else if (
        checkErrorMessages(errorMessages, ApiErrorMsg.noAbnFoundForClaim) ||
        checkErrorMessages(errorMessages, ApiErrorMsg.dspApiNoAbnError)
      ) {
        navigate('/claim-obligation', {
          state: {
            organisationId: claimObligationResponse.variables?.organisationId,
            businessName: response?.claimObligation?.dspBusinessName ?? '',
            businessAbn: response?.claimObligation?.dspAbn ?? '',
            role: claimObligationResponse.variables?.role,
            dsp: claimObligationResponse.variables?.dsp,
          },
        });
      } else if (checkErrorMessages(errorMessages, ApiErrorMsg.alreadyClaimed)) {
        navigate('/authorisation', {
          state: {
            addingBusiness: true,
            claimAlreadyClaimed: true,
          },
        });
      } else {
        setInlineError(true);
      }
    },
    onError: () => setInlineError(true),
  });

  const handleChange = (newvalue: unknown) => {
    setSelectedBusiness(newvalue as SelectOptionType);
  };

  const handleSubmit = () => {
    setMyobSsoError(false);
    setDspUnauthError(false);
    setInlineError(false);
    setActiveErrorCode(undefined);
    claimObligation({
      variables: {
        organisationId: selectedBusiness?.value,
        role: 'role',
        dsp: dsp.toUpperCase(),
        updateCounter,
      },
    });
  };

  const selectBusinessPageWrapperColumns = useBreakpointValue({
    base: '2/14',
    large: '2/11',
    xl: '2/9',
  }) as string;

  const selectBusinessPageSelectStyles: StylesConfig<any> = {
    ...businessSelectStyles,
    control: (styles) => ({
      ...styles,
      maxWidth: '384px',
      maxHeight: '48px',
      border: '2px solid rgba(92, 102, 112, 1)',
      borderRadius: '8px',
      padding: '8px 8px',
    }),

    placeholder: (styles) => ({
      ...styles,
      display: 'flex',
      maxheight: '79px',
      alignItems: 'flex-start',
    }),
  };

  return (
    <>
      {myobSsoError && (
        <AlertComponent
          title="Problem connecting to ABS Business Reporting"
          textHeaderComponent={
            <>
              Please make sure you have linked the User ID for the company file to your my.MYOB. Visit{' '}
              <Link href={externalLinks.myobSsoInstructions} isExternal>
                MYOB instructions
              </Link>{' '}
              for help resolving this issue.
            </>
          }
          variation="error"
          alertProps={{ isDismissible: true, margin: '16px auto 0' }}
          errorCode={activeErrorCode}
          onDismiss={() => setMyobSsoError(false)}
        />
      )}
      {dspUnauthError && (
        <AlertComponent
          title="Insufficient access  permissions to selected business"
          textHeaderComponent={
            <>
              Only the owner, administrators, advisors and accountants/bookkeepers can complete this report. Please
              contact the business owner or administrator of {selectedBusiness?.label} for assistance.
            </>
          }
          variation="error"
          alertProps={{ isDismissible: true, margin: '16px auto 0' }}
          errorCode={activeErrorCode}
          onDismiss={() => setDspUnauthError(false)}
        />
      )}
      <Grid
        marginTop={48}
        className="container-grid"
        margin="0px"
        padding="0px"
        templateColumns={useBreakpointValue(gridBreakPoints as Record<string, unknown>) as string}
      >
        <Card
          paddingTop={myobSsoError ? '77px 0px 0px 0px' : '0px 0px 0px 0px'}
          column={selectBusinessPageWrapperColumns}
          className="select-business-card"
          marginTop={{ base: '38px', medium: '48px' }}
        >
          <Flex gap={48} direction="column" alignSelf="flex-start" margin="0px" padding="0px">
            <GoBackButton onClick={() => navigate('/authorisation', { state: { addingBusiness: true } })} />
            <Image
              alignSelf="flex-start"
              src={dspLogo}
              alt={`${dsp} company logo`}
              height={48}
              width={116.87}
              testId="select-business-logo"
            />
            <Heading className="select-business-heading" testId="select-business-heading" level={1}>
              Connect to ABS Business Reporting
            </Heading>
            <Text className="select-business-text" testId="select-business-text">
              ABS Business Reporting is requesting access to the following from your {dsp} account
            </Text>
            <Flex className="select-business-permissions" testId="select-business-permissions">
              <Text className="options-heading">View your:</Text>
              <Text className="business-reports-setting-text">Business reports and organisation settings</Text>
            </Flex>
            <Flex direction="column" gap="8px" testId="select-business-input">
              <label className="options-heading" data-testid="select-business-label" htmlFor="select-business-input">
                Business
              </label>
              <Select
                value={selectedBusiness}
                inputId="select-business-input"
                styles={selectBusinessPageSelectStyles}
                options={selectOptions}
                maxMenuHeight={215}
                onChange={(selected) => handleChange(selected)}
                placeholder="Choose a business"
                components={{
                  DropdownIndicator: CustomDropdownIndicator,
                  Control,
                }}
              />
            </Flex>
            <div className="connect-business-btn-container">
              <Button
                variation="primary"
                className="select-business-btn"
                onClick={handleSubmit}
                isDisabled={selectedBusiness === undefined}
                isLoading={claimObligationResponse.loading}
                loadingText="Loading..."
                padding={12}
                gap={4}
              >
                <ConnectBusinessIcon width={24} height={24} />
                Connect business
              </Button>
            </div>
          </Flex>
          {inlineError && <InlineError styles="select-business-inline-error" />}
        </Card>
      </Grid>
    </>
  );
};

export default SelectBusinessPage;
